import { hydrateReactRootComponent } from '../../core/js/hydrate-react.js';
import { observeImages } from '../../core/js/lazy-loading.js';
import { registerServiceWorker } from '../../core/js/register-service-worker.js';
import { Frontpage } from '../../../src/byfunn/views/layouts/front/Frontpage.js';
import { initMblTracker } from '../../core/js/mbl-tracker.js';
import { getSessionData } from '../../core/js/schibsted-account/index.server.js';
import { initIterate } from '../../core/js/init-iterate.js';
import { userDataStoreApi } from '../../core/js/user-data-store/api.client.js';
import { initSourcepoint } from '../../core/js/sourcepoint.js';

window.userDataStoreApi = userDataStoreApi;
window.addEventListener('DOMContentLoaded', async () => {
  await hydrateReactRootComponent(Frontpage);
  observeImages();
  getSessionData().then((sessionData) => {
    initMblTracker(sessionData);
  });
  initSourcepoint();
  initIterate();
});

window.addEventListener('load', () => {
  import('../js-chunks/braze.js').catch((e) => console.error(e));
});

registerServiceWorker();
