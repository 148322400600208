// PWAOverlay.tsx
import React from 'react';

import PWAInstallButton from '../articles/PWAInstallButton.js';
import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';

interface PWAPopupProps {
  handleClose: () => void;
  setPWAInstalled: () => void;
  isIOS?: boolean;
}

const PWAPopup: React.FC<PWAPopupProps> = ({
  setPWAInstalled,
  isIOS,
  handleClose,
}) => {
  const { trackClickUIElement } = usePulseContext();

  const handleDismiss = () => {
    trackClickUIElement({
      object: {
        id: `pwa-overlay:dismiss`,
        name: 'Dismiss pwa-overlay prompt',
        elementType: 'Button',
      },
    });
    handleClose();
  };

  const handleInstall = () => {
    trackClickUIElement({
      object: {
        id: `pwa-overlay:install`,
        name: 'Install pwa from overlay',
        elementType: 'Button',
      },
    });
    setPWAInstalled();
  };

  return (
    <div className="pwa-popup">
      <div className="top-container">
        <div className="title">Legg til Vink på hjemskjerm!</div>
        <button className="close" onClick={handleDismiss}>
          Lukk
        </button>
      </div>

      <div className="info-container">
        <img
          className="app-icon"
          src="https://cdn.aftenposten.no/vink/images/icons/vink_app.svg"
          alt="Vink-app icon"
        />
        <div>
          {isIOS ? (
            <div>
              Legg til på hjemskjerm ved å trykke på{' '}
              <img
                className="share-icon"
                src="https://cdn.aftenposten.no/vink/images/icons/share_blue.svg"
                alt="share-icon on iOS menu"
              />
              , deretter <i>&quot;legg til på hjemskjerm&quot;</i>.
            </div>
          ) : (
            <div>
              Få alt innholdet til Vink på hjemskjermen ved å klikke på knappen
              <i>&quot;Legg til&quot;</i>.
            </div>
          )}
        </div>
        {!isIOS && (
          <div className="buttons-container">
            <button className="pwa-button white" onClick={handleDismiss}>
              Senere
            </button>
            <PWAInstallButton setPWAInstalled={handleInstall} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PWAPopup;
