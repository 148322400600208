import React from 'react';

import RecommendationCard from './RecommendationCard.js';

type Recommendation = {
  id: string;
  title: string;
  grade: number;
  area?: string;
};

interface MessageProps {
  header: string;
  detail?: string;
  recommendations?: Recommendation[];
  descriptions?: string[];
}

const MessageDesc: React.FC<MessageProps> = ({
  header,
  detail,
  recommendations,
  descriptions,
}) => {
  return (
    <div className="message">
      <p className="message__header">{header}</p>
      {detail && <p className="message__detail">{detail}</p>}
      {recommendations &&
        recommendations.map((rec, index) => (
          <div key={rec.id}>
            <RecommendationCard
              id={rec.id}
              title={rec.title}
              grade={rec.grade}
              area={rec.area}
            />
            <p className="message__description">
              {descriptions?.[index] ?? ''}
            </p>
          </div>
        ))}
    </div>
  );
};

export default MessageDesc;
