import React from 'react';

import type { Chunk } from '../../../types/frapi-types.js';

import Teaser from '../teasers/Teaser.js';
import BundleHeader from './BundleHeader.js';
import {
  constructBundleId,
  isManualBundle,
  shouldCompressBundle,
} from './utils.js';
import BundleOutro from './BundleOutro.js';

const LIMIT = 7;
const BundleMobile: React.FC<Chunk> = (bundle: Chunk) => {
  const bundleItems = bundle.items;
  const story = bundleItems[0]?.storyTag;

  const hasMain = shouldCompressBundle(bundle, true);
  const isBundle = isManualBundle(bundle);
  const classNames = ['bundle', hasMain ? 'big' : ''];

  return (
    <section key={`bundle-${bundle.id}`} className={classNames.join(' ')}>
      {story && isBundle && <BundleHeader story={story} />}

      <div className="bundle-teaser-container">
        {hasMain && (
          <Teaser
            {...bundleItems[0]}
            mobile
            size="big"
            index={bundle.index}
            positionInBundle={1}
            bundleId={bundle.id}
          />
        )}
        <div
          className={`bundle-teasers ${
            bundleItems.length === 3 ? 'wrapped' : ''
          }`}
        >
          {bundleItems.slice(0, LIMIT).map((bundleItem, i) => {
            if (hasMain && i === 0) {
              return null;
            }

            return (
              <Teaser
                key={bundleItem.id}
                {...bundleItem}
                mobile
                size={
                  bundleItems.length === 3
                    ? 'medium'
                    : bundleItems.length === 2
                      ? 'big'
                      : 'small'
                }
                index={
                  bundle.index
                    ? !hasMain
                      ? bundle.index + i
                      : bundle.index + 1
                    : undefined
                }
                fullWidthTeaser={false}
                positionInBundle={isBundle ? i + 1 : undefined}
                bundleId={isBundle ? constructBundleId(bundle) : undefined}
              />
            );
          })}
        </div>
      </div>
      {story && isBundle && <BundleOutro story={story} bundle={bundle} />}
    </section>
  );
};

export default BundleMobile;
