import React from 'react';

const TeaserGrade: React.FC<{ grade: number }> = ({ grade }) => (
  <div className="front-grade">
    <span className="bold-grade">{grade}</span>
    <div className="marks">
      {[...Array(6)].map((_, i) => (
        <div className={`mark ${i <= grade - 1 ? 'bold' : ''}`} key={i} />
      ))}
    </div>
  </div>
);

export default TeaserGrade;
