import React, { useEffect, useState } from 'react';

import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { Animated } from '../../components/commonComponents/Animated.js';
import { useOutsideClick } from '../../../../core/react/hooks/index.js';

interface OverlayProps {
  children: React.ReactNode;
  title?: string;
  handleOverlay?: () => void;
  className?: string;
}

export const Overlay: React.FC<OverlayProps> = ({
  children,
  title,
  handleOverlay,
  className,
}) => {
  const { trackClickUIElement } = usePulseContext();
  const classNames = ['overlay', className].join(' ');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      if (handleOverlay) {
        handleOverlay();
      }
    }, 500);
  };

  const handleClick = () => {
    trackClickUIElement({
      object: {
        id: 'modal:close-button',
        name: 'Close modal button',
        elementType: 'Button',
      },
    }).finally(() => {
      handleClose();
    });
  };
  const containerRef = useOutsideClick(handleClose);

  return (
    <div className={classNames}>
      <Animated show={show}>
        <div className="modal" ref={containerRef}>
          {title && handleOverlay && (
            <div className="modal-header">
              <button className="modal-close" onClick={handleClick}>
                Lukk
              </button>
              <div className="modal-title">{title}</div>
            </div>
          )}
          {children}
        </div>
      </Animated>
    </div>
  );
};
