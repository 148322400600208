import React, { useRef } from 'react';
import { ApReels, VinkReels } from '@schibsted-regional/components';

import type { ApReelsProps } from '@schibsted-regional/components';
import type { SvpAsset } from '../../../core/bindings/data/fetch-stream-data.js';

import { isMobileApp } from '../../../core/utils/is-mobile.js';
import { useReelsOverlay } from '../../../regional/rendering/blocks/Reels/useReelsOverlay.js';
import { useScrollTracker } from '../shared/hooks/useScrollTracker.js';

type CommonProps = {
  title: string;
  playlistId: string;
  reels: SvpAsset[];
  url: string;
};

type VinkProps = CommonProps & {
  brand: 'vink';
};

type ApProps = CommonProps & {
  brand: 'ap';
  rowNumber: number;
};

const ReelsSlider = (props: VinkProps | ApProps) => {
  const { title, reels, playlistId, url, brand } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  useScrollTracker(containerRef);

  const preloadUrls = reels.map(
    ({ id }) => `${url}/stories/${id}?playlistId=${playlistId}`,
  );

  const previews = reels.map((video, index) => {
    const { metadata } = video.additional;

    return {
      id: video.id,
      title: video.title,
      link: `${url}/stories/${video.id}?playlistId=${playlistId}`,
      duration: Number(video.duration),
      imagePreviewUrl: video.images.main,
      videoPreviewUrl: `${
        metadata?.preview_vivi_category ||
        metadata?.preview_360p ||
        metadata?.preview ||
        metadata?.preview_hq
      }`,
      'data-pulse-entity-id': `reel-${video.id}`,
      'data-pulse-entity-name': `Reel ${video.id}: ${video.title}`,
      'data-pulse-position-in-bundle': `${index}`,
      'data-pulse-source': 'reels-widget',
      'data-pulse-product-name': 'reels-widget',
      ...(brand === 'ap' && {
        'data-pulse-position': `${props.rowNumber}`,
        'data-pulse-product-name': `reels-widget:reelname:${title}`,
      }),
    };
  });

  const { openReel } = useReelsOverlay({ preloadItems: preloadUrls });

  const onPreviewClick: ApReelsProps['onPreviewClick'] = (event, video) => {
    const useIframeOverlay = !isMobileApp();

    if (useIframeOverlay) {
      event.preventDefault();
      openReel(video.link);
    }
  };

  const commonProps: ApReelsProps = { title, previews, onPreviewClick };

  const reelsCarousel = getBrandReels(brand, commonProps, containerRef);

  return (
    <div
      data-pulse-entity-id={`${brand}-reels`}
      data-pulse-entity-name={`${brand} reels sliders`}
      data-pulse-entity-type="Reel"
      {...(brand === 'ap' && {
        'data-pulse-position': `${props.rowNumber}`,
        'data-pulse-product-name': `reels-widget:reelname:${title}`,
      })}
      className="reels-widget"
    >
      {reelsCarousel}
    </div>
  );
};

const getBrandReels = (
  brand: 'vink' | 'ap',
  commonProps: ApReelsProps,
  containerRef: React.RefObject<HTMLDivElement>,
) => {
  if (brand === 'ap') {
    return <ApReels ref={containerRef} {...commonProps} />;
  }

  return <VinkReels {...commonProps} />;
};

export { ReelsSlider };
