import React from 'react';

import { LinkWithPulse } from '../../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';
import { sections } from '../../../layouts/front/sectionHelpers.js';

interface NavigationLinkProps {
  location: string;
  name: string;
  className: string;
  imgUrl: string;
  activeSection?: string;
}

const isActive = (
  activeSection: string | undefined,
  location: string,
): boolean => {
  const sectionName = location.split('/').pop();

  return (
    activeSection === sectionName ||
    sections[(sectionName || '') as keyof typeof sections] === location
  );
};

const NavigationLink: React.FC<NavigationLinkProps> = ({
  location,
  name,
  className,
  imgUrl,
  activeSection,
}) => {
  const slug = location.split('/').pop() || '';
  const slugWithDashes = slug
    .split('-')
    .map((word) => word.toLowerCase())
    .join('-');

  const classNames = [
    'link',
    className,
    isActive(activeSection, location) ? 'active' : '',
  ];

  return (
    <LinkWithPulse
      className={classNames.join(' ')}
      href={location}
      id={`navigation:${slugWithDashes}`}
      name={`${slug} section in navigation header`}
    >
      <div className="icon">
        <img src={imgUrl} alt={`${name}-button`} />
      </div>
      <div className="section-title">{name}</div>
    </LinkWithPulse>
  );
};

export default NavigationLink;
