import React, { useEffect, useState } from 'react';

interface BeforeInstallPromptEvent extends Event {
  platforms: string[];
  userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

interface PWAInstallButtonProps {
  setPWAInstalled: () => void;
}

const PWAInstallButton: React.FC<PWAInstallButtonProps> = ({
  setPWAInstalled,
}) => {
  const [beforeInstallPrompt, setBeforeInstallPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const eventHandler = (event: Event) => {
      const installPromptEvent = event as BeforeInstallPromptEvent;
      setBeforeInstallPrompt(installPromptEvent);
    };

    window.addEventListener(
      'beforeinstallprompt',
      eventHandler as EventListener,
    );

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        eventHandler as EventListener,
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (beforeInstallPrompt) {
      // Prompt the user to install the PWA
      beforeInstallPrompt.prompt();
      // Wait for the user's choice/result from the prompt
      beforeInstallPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          setPWAInstalled();
        }
        setBeforeInstallPrompt(null);
      });
    }
  };

  return (
    <button className="pwa-button" onClick={handleInstallClick}>
      Legg til
    </button>
  );
};

export default PWAInstallButton;
