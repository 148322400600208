import type {
  AdPositionMapper,
  AdPositionInSection,
  PositionConfig,
  Placement,
  PlacementWithoutPosition,
} from '../types/ad-types.js';

const frontpagePlacements = {
  desktop: [
    {
      id: 'takeover',
      invCode: 'no-vink-wde-front_topscroller',
      allowedFormats: ['banner'],
      keywords: {
        'no-sno-adformat': ['topscroller'],
      },
      sizes: [[4, 4]],
    },
    {
      id: 'netboard_1',
      invCode: 'no-vink-wde-front_netboard_1',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['netboard_1'],
      },
      sizes: [
        [580, 400],
        [580, 500],
      ],
    },
    {
      id: 'netboard_2',
      invCode: 'no-vink-wde-front_netboard_2',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['netboard_2'],
      },
      sizes: [
        [580, 400],
        [580, 500],
      ],
    },
    {
      id: 'netboard_3',
      invCode: 'no-vink-wde-front_netboard_3',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['netboard_3'],
      },
      sizes: [
        [580, 400],
        [580, 500],
      ],
    },
    {
      id: 'netboard_4',
      invCode: 'no-vink-wde-front_netboard_4',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['netboard_4'],
      },
      sizes: [
        [580, 400],
        [580, 500],
      ],
    },
    {
      id: 'netboard_5',
      invCode: 'no-vink-wde-front_netboard_5',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['netboard_5'],
      },
      sizes: [
        [580, 400],
        [580, 500],
      ],
    },
    {
      id: 'brandboard_1',
      invCode: 'no-vink-wde-front_brandboard_1',
      allowedFormats: ['banner'],
      keywords: {
        'no-sno-adformat': ['brandboard_1'],
      },
      sizes: [
        [980, 300],
        [980, 500],
        [980, 600],
      ],
    },
    {
      id: 'brandboard_2',
      invCode: 'no-vink-wde-front_brandboard_2',
      allowedFormats: ['banner'],
      keywords: {
        'no-sno-adformat': ['brandboard_2'],
      },
      sizes: [
        [980, 300],
        [980, 500],
        [980, 600],
      ],
    },
    {
      id: 'brandboard_3',
      invCode: 'no-vink-wde-front_brandboard_3',
      allowedFormats: ['banner'],
      keywords: {
        'no-sno-adformat': ['brandboard_3'],
      },
      sizes: [
        [980, 300],
        [980, 500],
        [980, 600],
      ],
    },
    {
      id: 'sponsorstripe',
      invCode: 'no-vink-wde-front_sponsorstripe',
      allowedFormats: ['native'],
      keywords: {
        'no-sno-adformat': ['sponsorstripe'],
      },
      sizes: [[1, 1]],
    },
  ],
  tablet: [],
  mobile: [
    {
      id: 'takeover',
      invCode: 'no-vink-wph-front_topscroller',
      allowedFormats: ['banner'],
      keywords: {
        'no-sno-adformat': ['topscroller'],
      },
      sizes: [[4, 4]],
    },
    {
      id: 'board_1',
      invCode: 'no-vink-wph-front_board_1',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['board_1'],
      },
      sizes: [
        [320, 250],
        [320, 400],
      ],
    },
    {
      id: 'board_2',
      invCode: 'no-vink-wph-front_board_2',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['board_2'],
      },
      sizes: [
        [320, 250],
        [320, 400],
      ],
    },
    {
      id: 'board_3',
      invCode: 'no-vink-wph-front_board_3',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['board_3'],
      },
      sizes: [
        [320, 250],
        [320, 400],
      ],
    },
    {
      id: 'board_4',
      invCode: 'no-vink-wph-front_board_4',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['board_4'],
      },
      sizes: [
        [320, 250],
        [320, 400],
      ],
    },
    {
      id: 'board_5',
      invCode: 'no-vink-wph-front_board_5',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['board_5'],
      },
      sizes: [
        [320, 250],
        [320, 400],
      ],
    },
    {
      id: 'sponsorstripe',
      invCode: 'no-vink-wph-front_sponsorstripe',
      allowedFormats: ['native'],
      keywords: {
        'no-sno-adformat': ['sponsorstripe'],
      },
      sizes: [[1, 1]],
    },
  ],
};

const articlePlacements = {
  desktop: [
    {
      id: 'takeover',
      invCode: 'no-vink-wde-article_topscroller',
      allowedFormats: ['banner'],
      keywords: {
        'no-sno-adformat': ['topscroller'],
      },
      sizes: [[4, 4]],
    },
    {
      id: 'netboard_1',
      invCode: 'no-vink-wde-article_netboard_1',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['netboard_1'],
      },
      sizes: [
        [580, 400],
        [580, 500],
      ],
    },
    {
      id: 'netboard_2',
      invCode: 'no-vink-wde-article_netboard_2',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['netboard_2'],
      },
      sizes: [
        [580, 400],
        [580, 500],
      ],
    },
    {
      id: 'netboard_3',
      invCode: 'no-vink-wde-article_netboard_3',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['netboard_3'],
      },
      sizes: [
        [580, 400],
        [580, 500],
      ],
    },
    {
      id: 'netboard_4',
      invCode: 'no-vink-wde-article_netboard_4',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['netboard_4'],
      },
      sizes: [
        [580, 400],
        [580, 500],
      ],
    },
    {
      id: 'netboard_5',
      invCode: 'no-vink-wde-article_netboard_5',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['netboard_5'],
      },
      sizes: [
        [580, 400],
        [580, 500],
      ],
    },
    {
      id: 'brandboard_1',
      invCode: 'no-vink-wde-article_brandboard_1',
      allowedFormats: ['banner'],
      keywords: {
        'no-sno-adformat': ['brandboard_1'],
      },
      sizes: [
        [980, 300],
        [980, 500],
        [980, 600],
      ],
    },
    {
      id: 'brandboard_2',
      invCode: 'no-vink-wde-article_brandboard_2',
      allowedFormats: ['banner'],
      keywords: {
        'no-sno-adformat': ['brandboard_2'],
      },
      sizes: [
        [980, 300],
        [980, 500],
        [980, 600],
      ],
    },
    {
      id: 'brandboard_3',
      invCode: 'no-vink-wde-article_brandboard_3',
      allowedFormats: ['banner'],
      keywords: {
        'no-sno-adformat': ['brandboard_3'],
      },
      sizes: [
        [980, 300],
        [980, 500],
        [980, 600],
      ],
    },
    {
      id: 'sponsorstripe',
      invCode: 'no-vink-wde-article_sponsorstripe',
      allowedFormats: ['native'],
      keywords: {
        'no-sno-adformat': ['sponsorstripe'],
      },
      sizes: [[1, 1]],
    },
  ],
  tablet: [],
  mobile: [
    {
      id: 'takeover',
      invCode: 'no-vink-wph-article_topscroller',
      allowedFormats: ['banner'],
      keywords: {
        'no-sno-adformat': ['topscroller'],
      },
      sizes: [[4, 4]],
    },
    {
      id: 'board_1',
      invCode: 'no-vink-wph-article_board_1',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['board_1'],
      },
      sizes: [
        [320, 250],
        [320, 400],
      ],
    },
    {
      id: 'board_2',
      invCode: 'no-vink-wph-article_board_2',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['board_2'],
      },
      sizes: [
        [320, 250],
        [320, 400],
      ],
    },
    {
      id: 'board_3',
      invCode: 'no-vink-wph-article_board_3',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['board_3'],
      },
      sizes: [
        [320, 250],
        [320, 400],
      ],
    },
    {
      id: 'board_4',
      invCode: 'no-vink-wph-article_board_4',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['board_4'],
      },
      sizes: [
        [320, 250],
        [320, 400],
      ],
    },
    {
      id: 'board_5',
      invCode: 'no-vink-wph-article_board_5',
      allowedFormats: ['banner', 'native'],
      keywords: {
        'no-sno-adformat': ['board_5'],
      },
      sizes: [
        [320, 250],
        [320, 400],
      ],
    },
    {
      id: 'sponsorstripe',
      invCode: 'no-vink-wph-article_sponsorstripe',
      allowedFormats: ['native'],
      keywords: {
        'no-sno-adformat': ['sponsorstripe'],
      },
      sizes: [[1, 1]],
    },
  ],
};

const frontpagePositionConfig: PositionConfig = {
  desktop: {
    netboard_1: 14,
    netboard_2: null,
    netboard_3: null,
    netboard_4: null,
    netboard_5: null,
    brandboard_1: 3,
    brandboard_2: 7,
    brandboard_3: 12,
  },
  mobile: {
    board_1: 1,
    board_2: 3,
    board_3: 7,
    board_4: 11,
    board_5: 14,
  },
};

const sectionPositionConfig_1: PositionConfig = {
  desktop: {
    netboard_1: 1,
    netboard_2: 6,
    netboard_3: 11,
    netboard_4: 16,
    netboard_5: 21,
  },
  mobile: {
    board_1: 1,
    board_2: 6,
    board_3: 11,
    board_4: 16,
    board_5: 21,
  },
};

const sectionPositionConfig_2: PositionConfig = {
  desktop: {
    netboard_1: 1,
    netboard_2: 5,
    netboard_3: 9,
    netboard_4: 14,
    netboard_5: 19,
  },
  mobile: {
    board_1: 1,
    board_2: 7,
    board_3: null,
    board_4: null,
    board_5: null,
  },
};

const sectionPositionConfig_3: PositionConfig = {
  desktop: {
    brandboard_1: 6,
    brandboard_2: 13,
    brandboard_3: 20,
    netboard_1: 24,
    netboard_2: null,
    netboard_3: null,
    netboard_4: null,
    netboard_5: null,
  },
  mobile: {
    board_1: 6,
    board_2: 13,
    board_3: 20,
    board_4: 27,
    board_5: 36,
  },
};

const adPositionConfig: AdPositionInSection = {
  frontpage: frontpagePositionConfig,
  Opplevelser: sectionPositionConfig_1,
  Restaurantguiden: sectionPositionConfig_2,
  'Film og serier': sectionPositionConfig_2,
  topplister: sectionPositionConfig_3,
};

const getPlacements = (
  section: string,
): { frontpage: AdPositionMapper; article: AdPositionMapper } => {
  const relevantSection = adPositionConfig[section];

  const mergeConfigsWithPositions = ([deviceName, config]: [
    string,
    PlacementWithoutPosition[],
  ]): [string, Placement[]] => {
    return [
      deviceName,
      config.map((placementConfig) => ({
        ...placementConfig,
        position: relevantSection[deviceName][placementConfig.id],
      })),
    ];
  };

  const frontpagePlacementsWithPositions: AdPositionMapper = Object.fromEntries(
    Object.entries(frontpagePlacements).map(mergeConfigsWithPositions),
  );

  const articlePlacementsWithPositions: AdPositionMapper = Object.fromEntries(
    Object.entries(articlePlacements).map(mergeConfigsWithPositions),
  );

  return {
    frontpage: frontpagePlacementsWithPositions,
    article: articlePlacementsWithPositions,
  };
};

export { frontpagePlacements, articlePlacements, getPlacements };
