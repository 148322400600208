import React, { useRef } from 'react';

import type { FrapiTeaserItemSized } from '../../../types/frapi-types.js';
import type { TeaserListProps } from './TeaserList.js';
import type { TeaserSavedProps } from './TeaserSaved.js';

import { createTargetObject } from '../../../../core/mandatory-integrations/pulse/event-data/index.js';
import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { getPulseAttributesFromElement } from '../../../../core/mandatory-integrations/pulse/utils/index.js';
import { getPulseMeta } from './pulseMeta.js';

export type WrappedTeaser =
  | FrapiTeaserItemSized
  | TeaserSavedProps
  | TeaserListProps;

interface TeaserWrapperProps {
  teaser: WrappedTeaser;
  classNames: string;
  children: React.ReactNode;
  link?: string;
  isMobile?: boolean;
  pageType?: string;
  bundleId?: string;
  externalSource?: string;
}

const getTargetUrl = (url: string, path: string) => {
  const urlOrigin = new URL(url).origin;

  return `${urlOrigin}${path}`;
};

const generatePageId = (page: string, pageType?: string) => {
  if (pageType) return pageType;
  switch (page) {
    case '/':
      return 'frontpage';
    case '/search':
      return 'search';
  }

  return 'section-page';
};

const TeaserWrapper: React.FC<TeaserWrapperProps> = ({
  teaser,
  classNames,
  children,
  link,
  isMobile,
  pageType,
  externalSource,
}) => {
  const href = link || `/artikkel/${teaser.id}/${teaser.slug}`;
  const refToLink = 'innerRef' in teaser ? teaser.innerRef : null;

  const { trackClickUIElement } = usePulseContext();
  const articleRef = useRef(null);

  const handleOnClick = () => {
    if (!articleRef.current) {
      return;
    }

    const pulseAttributes = getPulseAttributesFromElement(articleRef.current);

    const {
      entityId,
      entityType,
      elementPosition,
      elementPositionInBundle,
      entityImageUrl,
      entitySize,
      source,
      elementBundleId,
    } = pulseAttributes;

    if (!entityId) {
      return;
    }

    trackClickUIElement({
      object: {
        id: entityId,
        elementType: entityType || 'ArticleReference',
        name: 'Teaser',
        layout: {
          elementSize: teaser?.size || undefined,
          imageUrl: entityImageUrl,
        },
        page: {
          id: generatePageId(window.location.pathname, pageType),
          type: 'Listing',
        },
        placement: {
          primaryPosition: elementPosition || 0,
          positionInBundle: elementPositionInBundle,
          source,
        },
        custom: {
          'spt:device': isMobile ? 'phone' : 'desktop',
          'spt:bundleId': elementBundleId,
          ...(entitySize
            ? {
                'spt:height': entitySize.height,
                'spt:width': entitySize.width,
              }
            : {}),
        },
      },
      target: {
        ...createTargetObject(pulseAttributes, externalSource || 'vink'),
        url: link || getTargetUrl(window.location.href, href),
      },
    });
  };

  return (
    <article
      className={classNames}
      {...getPulseMeta(teaser, href)}
      ref={articleRef}
    >
      <a
        className="teaser-link"
        href={href}
        ref={refToLink}
        onClick={handleOnClick}
      >
        {children}
      </a>
    </article>
  );
};

export default TeaserWrapper;
