import { useEffect } from 'react';

import type { SessionData } from '../../../../public-src/core/js/schibsted-account/types.js';
import type { SiteVersion } from '../../../core/system/rendering/types.js';
import type { GetEnvironmentId } from '../../../core/mandatory-integrations/pulse/types/environment.js';

const useInitializeAds = (
  getEnvironmentId: GetEnvironmentId,
  session: SessionData,
  siteVersion: SiteVersion,
) => {
  useEffect(() => {
    if (session !== null) {
      import('../../../../public-src/byfunn/js-chunks/ads.js').then(
        ({ initAds }) => {
          initAds(siteVersion);
        },
      );
    }
  }, [getEnvironmentId, session, siteVersion]);
};

export default useInitializeAds;
