import React from 'react';

import type { Tag } from '../../../../../core/data-sources/frapi/data-types.js';

import { filterTagsOnType, makeTagTitle } from '../../articles/helpers.js';
import { sectionTitleMappings } from '../../../layouts/front/sectionHelpers.js';

const TeaserTag: React.FC<{ tags: Tag[]; section: string }> = ({
  tags,
  section,
}) => {
  const typeTags = filterTagsOnType(tags, ['kjøkken', 'format', 'tema'], 1);

  return (
    <div className="bottom-tags">
      {typeTags.length > 0
        ? makeTagTitle(typeTags[0].title)
        : sectionTitleMappings(section)}
    </div>
  );
};
export default TeaserTag;
