import { useState, useEffect } from 'react';

type UseScrollDirectionHook = 'up' | 'down';

export const useScrollDirection = (): UseScrollDirectionHook => {
  const [scrollDirection, setScrollDirection] =
    useState<UseScrollDirectionHook>('up');
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      if (currentScrollTop > lastScrollTop) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }

      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  return scrollDirection;
};
