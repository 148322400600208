import type { Chunk } from '../../../types/frapi-types.js';

import { slugify } from '../teasers/slugify.js';

export const constructBundleId = (bundle: Chunk) => {
  const items = bundle.items;
  const title = items[0]?.storyTag?.title;

  return `${title ? `${slugify(title)}_` : ''}${items
    .map((i) => i.id)
    .join(',')}`;
};

export const shouldCompressBundle = (bundleTeaser: Chunk, mobile = false) => {
  const length = bundleTeaser.items.length;
  if (mobile) {
    return true;
  }

  return length >= 5;
};

export const isManualBundle = (bundle: Chunk) => bundle.type !== 'chunk';
