import { useState, useCallback, useEffect, useRef } from 'react';

export interface DimensionObject {
  width: number;
  height: number;
}

export type UseDimensionsHook = [
  (node: HTMLElement | null) => void,
  DimensionObject,
  HTMLElement | null,
];

export interface UseDimensionsArgs {
  liveMeasure?: boolean;
}

function getDimensionObject(node: HTMLElement | null): DimensionObject {
  if (!node) {
    return { width: 0, height: 0 };
  }
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
  };
}

function useDimensions({
  liveMeasure = true,
}: UseDimensionsArgs = {}): UseDimensionsHook {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [node, setNode] = useState<HTMLElement | null>(null);
  const resizeTimeoutRef = useRef<number | null>(null);

  const ref = useCallback((node: HTMLElement | null) => {
    setNode(node);
  }, []);

  const measure = useCallback(() => {
    if (node) {
      setDimensions(getDimensionObject(node));
    }
  }, [node]);

  useEffect(() => {
    if (liveMeasure && typeof window !== 'undefined') {
      const handleResize = () => {
        if (resizeTimeoutRef.current) {
          clearTimeout(resizeTimeoutRef.current);
        }

        resizeTimeoutRef.current = window.setTimeout(measure, 100);
      };

      measure();
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', measure);

      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', measure);

        if (resizeTimeoutRef.current) {
          clearTimeout(resizeTimeoutRef.current);
        }
      };
    }

    return () => {};
  }, [liveMeasure, measure]);

  return [ref, dimensions, node];
}

export default useDimensions;
