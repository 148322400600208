import React from 'react';

import type { Recommendation } from './types.js';

import { frontendConfig } from '../../../../../../core/system/config/frontend.server.js';
import TeaserGrade from '../../../../components/teasers/teaserComponents/TeaserGrade.js';
import { LinkWithPulse } from '../../../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';

type RecommendationCardProps = Recommendation;

const vinkUrl =
  frontendConfig.serverRuntime === 'convox-production'
    ? 'https://vink.aftenposten.no/artikkel/'
    : '/artikkel/';

const RecommendationCard: React.FC<RecommendationCardProps> = ({
  id,
  title,
  grade,
}) => {
  return (
    <LinkWithPulse
      href={vinkUrl + id}
      className="recommendation-card"
      id="vink-o-mat:article"
      name={`Anmeldelse av ${title}`}
    >
      <div className="recommendation-card__title">{title}</div>
      <div className="recommendation-card__info">
        <TeaserGrade grade={grade} />
        <div className="recommendation-card__info__divider" />
        <img
          className="send-icon-img"
          src={`${frontendConfig.cdnUrlInternal}/vink/images/icons/arrows/arrow_blue2.svg`}
          alt="Arrow right"
        />
      </div>
    </LinkWithPulse>
  );
};

export default RecommendationCard;
