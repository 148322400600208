import React from 'react';

import type { FrapiListItem, TeaserSize } from '../../../types/frapi-types.js';

import Image from './teaserComponents/Image.js';
import TeaserWrapper from './TeaserWrapper.js';
import { teaserImageMap } from '../commonComponents/imageUtils.js';

export interface TeaserListProps extends FrapiListItem {
  index?: number;
  size: TeaserSize;
  slug: string;
  mobile: boolean;
  extraPath?: string;
  width?: number;
  height?: number;
}

const TeaserList: React.FC<TeaserListProps> = (teaser) => {
  const { properties } = teaser;
  const link = `${teaser.extraPath ?? ''}${teaser.id.replace('.', '/')}`;
  const imgSize =
    teaser.width && teaser.height
      ? { w: teaser.width, h: teaser.height }
      : teaserImageMap[teaser.mobile ? 'mobile' : 'desktop'][teaser.size];

  const classNames = ['teaser-list', 'teaser', teaser.size].join(' ');

  return (
    <TeaserWrapper
      teaser={teaser}
      classNames={classNames}
      link={link}
      pageType="list:teaser"
    >
      <div>
        {properties.image ? (
          <Image
            imageAsset={{ id: properties.image }}
            width={imgSize.w}
            height={imgSize.h}
            title={properties.title}
          />
        ) : (
          <div className="no-image-placeholder" />
        )}
        <div className="text-wrapper">
          <div className="top-list-tag">Toppliste</div>
          <div className="title">{properties.title}</div>
        </div>
      </div>
    </TeaserWrapper>
  );
};
export default TeaserList;
