import React from 'react';

import type { FrapiList, FrapiListItem } from '../../../types/frapi-types.js';

import Carousel from '../../components/commonComponents/Carousel.js';
import TeaserList from '../../components/teasers/TeaserList.js';

const RestaurantsWidget: React.FC<{
  data: FrapiList;
  isMobile: boolean;
}> = ({ data, isMobile }) => {
  const lists = data.items?.filter(
    (item) => item.type === 'list',
  ) as FrapiListItem[];

  if (lists.length === 0) {
    return null;
  }

  return (
    <section className="restaurants-wrapper">
      <div className="restaurants-title">{data.properties.h1}</div>

      <Carousel>
        {lists.map((item) => (
          <TeaserList
            {...item}
            key={item.id}
            mobile={isMobile}
            size="small"
            slug=""
            extraPath="/mat-og-drikke/"
            width={580}
            height={768}
          />
        ))}
      </Carousel>
    </section>
  );
};

export default RestaurantsWidget;
