import { useState, useEffect } from 'react';

import type { SessionData } from './types.js';

import { getSessionData } from './index.client.js';
import { console } from '../../../../src/core/system/console/index.js';

export const useSessionData = (): SessionData => {
  const [session, setSession] = useState<SessionData>(null);

  useEffect(() => {
    const checkSession = async () => {
      const maybeSession = await getSessionData();

      if (maybeSession) {
        setSession(maybeSession);
      } else {
        setSession(false);
      }
    };

    checkSession().catch((error) => {
      console.error(error);
    });
  }, []);

  return session;
};
