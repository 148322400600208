import React from 'react';

import type { FrapiTeaserItemSized } from '../../../types/frapi-types.js';

import Image from './teaserComponents/Image.js';
import { getTeaserImageSize } from '../commonComponents/imageUtils.js';
import TeaserKicker from './teaserComponents/TeaserKicker.js';

const TeaserFeature: React.FC<FrapiTeaserItemSized> = (
  t: FrapiTeaserItemSized,
) => {
  const imgSize = getTeaserImageSize(t);

  return (
    <>
      <div className="wrapper">
        <div className="text">
          <TeaserKicker {...t} />
          <div className="title">{t.title}</div>
          {t?.description && (
            <div className="feature-description">{t.description}</div>
          )}
          <div className="not-a-button">Les artikkel</div>
        </div>
        {t.image && imgSize && (
          <Image
            imageAsset={t.image}
            lazyLoad={t.index ? t.index >= 2 : undefined}
            title={t.title}
            width={imgSize.w}
            height={imgSize.h}
            size={t.size}
          />
        )}
      </div>
    </>
  );
};
export default TeaserFeature;
