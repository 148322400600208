export const slugify = (a: string) =>
  a
    .toLowerCase()
    .trim()
    .replace(/ /g, '-')
    .replace(/æ/g, 'ae')
    .replace(/ø/g, 'o')
    .replace(/å/g, 'a')
    .replace(/[^\w-]+/g, '')
    .replace(/\.$/, '');
