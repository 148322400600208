import { useEffect, useState } from 'react';

import type { SaveList } from '../../types/save-types.js';

import { userDataStoreApi } from '../../../../public-src/core/js/user-data-store/api.server.js';
import { DEFAULT_LIST_NAME } from '../components/saving/utils.js';
import { isErrorWithStatusCode } from '../../../core/utils/error.js';

export type UseGetSavedHook = [
  SaveList[],
  (items: SaveList[]) => void,
  boolean,
  boolean,
];

const findDefaultListIndex = (data: SaveList[]): number => {
  return data.findIndex((list) => list.name === DEFAULT_LIST_NAME);
};

const is404Error = (e: unknown): boolean => {
  return isErrorWithStatusCode(e) && e.status === 404;
};

const addDefaultList = (data: SaveList[]): SaveList[] => {
  const defaultList: SaveList = {
    name: DEFAULT_LIST_NAME,
    content: [],
    date: new Date().getTime(),
  };

  return [defaultList, ...data];
};

const moveDefaultListToFront = (
  data: SaveList[],
  defaultListIndex: number,
): SaveList[] => {
  const listWithoutDefault = data.filter((_, i) => i !== defaultListIndex);

  return [data[defaultListIndex], ...listWithoutDefault];
};

const updateDataWithDefaultList = async (
  lists: SaveList[],
  setSavedItems: (lists: SaveList[]) => void,
): Promise<void> => {
  const defaultListIndex = findDefaultListIndex(lists);

  if (defaultListIndex === 0) {
    await userDataStoreApi.setUserData('vink-lists', lists);
    setSavedItems(lists);

    return;
  }

  let modifiedLists: SaveList[] = [];

  if (defaultListIndex !== -1) {
    modifiedLists = moveDefaultListToFront(lists, defaultListIndex);
  } else {
    modifiedLists = addDefaultList(lists);
  }

  await userDataStoreApi.setUserData('vink-lists', modifiedLists);
  setSavedItems(modifiedLists);
};

export const useGetSaved = (): UseGetSavedHook => {
  const [savedItems, setSavedItems] = useState<SaveList[]>([]);
  const [hasError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let data: SaveList[] = [];

      try {
        const response =
          await userDataStoreApi.getUserData<SaveList[]>('vink-lists');
        data = response?.value ?? [];
      } catch (e) {
        // If the user has never saved anything, we get a 404 error
        if (is404Error(e)) {
          return;
        } else {
          setError(true);
        }
      }

      try {
        await updateDataWithDefaultList(data, setSavedItems);
      } catch {
        setError(true);
      }

      setLoading(false);
    })();
  }, []);

  return [savedItems, setSavedItems, isLoading, hasError];
};
