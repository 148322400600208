import React from 'react';

import { frontendConfig } from '../../../../../../core/system/config/frontend.server.js';

interface ChatInputProps {
  inputText: string;
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  sendMessage: (text: string, sender: 'user' | 'bot') => void;
}

const ChatInput: React.FC<ChatInputProps> = ({
  inputText,
  handleInputChange,
  handleKeyDown,
  sendMessage,
}) => {
  return (
    <div className="input-wrapper">
      <div className="input-container">
        <textarea
          className="input-field"
          placeholder="Skriv inn ønske selv"
          value={inputText}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      <button
        className="send-button"
        onClick={() => sendMessage(inputText, 'user')}
      >
        <img
          className="send-icon-img"
          src={`${frontendConfig.cdnUrlInternal}/vink/images/icons/search.svg`}
          alt="Search Icon"
        />
      </button>
    </div>
  );
};

export default ChatInput;
