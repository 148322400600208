export const isError = (error: unknown): error is Error =>
  error instanceof Error;

export const isErrorWithStatusText = (
  error: unknown,
): error is Error & { statusText: string } =>
  typeof error === 'object' && error !== null && 'statusText' in error;

export const isErrorWithStatusCode = (
  error: unknown,
): error is Error & { status: number | string } =>
  typeof error === 'object' && error !== null && 'status' in error;

export const getErrorMessage = (error: unknown): string =>
  isError(error) ? error.message : `${error}`;
