import React from 'react';

import type { FrapiTeaserItemSized } from '../../../types/frapi-types.js';

import Image from './teaserComponents/Image.js';
import TeaserKicker from './teaserComponents/TeaserKicker.js';
import { teaserImageMap } from '../commonComponents/imageUtils.js';

const TeaserGodt: React.FC<FrapiTeaserItemSized> = (teaser) => {
  const { image, title, mobile } = teaser;
  const imgSize =
    teaserImageMap[mobile ? 'mobile' : 'desktop'][teaser.size || 'medium'];

  return (
    <>
      {image && (
        <Image
          imageAsset={image}
          width={imgSize.w}
          height={imgSize.h}
          title={title}
          imageUrl={teaser.imageUrl}
        />
      )}
      <div className="text-wrapper">
        <TeaserKicker {...teaser} kicker="Fra godt.no" hideSaveButton />
        <div className="title">{title}</div>
        <div className="grade-tags">
          <img
            className="godt"
            src="https://cdn.aftenposten.no/vink/images/header/v2/godt.svg"
            alt="Godt.no"
          />
          <div className="bottom-tags">{teaser.kicker}</div>
        </div>
      </div>
    </>
  );
};
export default TeaserGodt;
