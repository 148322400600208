import React from 'react';

import type { Story } from '../../../types/frapi-types.js';

const BundleHeader: React.FC<{ story: Story }> = ({ story }) => {
  return (
    <div className="bundle-header">
      <div className="bundle-title-wrapper">
        {story && <div className="bundle-title">{story.title}</div>}
      </div>
    </div>
  );
};

export default BundleHeader;
