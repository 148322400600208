import React, { useEffect, useState } from 'react';

interface AnimatedProps {
  children: React.ReactNode;
  show: boolean;
}
export const Animated: React.FC<AnimatedProps> = ({ children, show }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return shouldRender ? (
    <div
      style={{
        animation: `${show ? 'slideIn' : 'slideOut'} 0.3s`,
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  ) : (
    <></>
  );
};
