import React from 'react';

import { frontendConfig } from '../../../../core/system/config/frontend.server.js';

interface DropDownMenuProps {
  onClick: () => void;
  active: boolean;
}

const DropDownButton: React.FC<DropDownMenuProps> = ({ onClick, active }) => {
  const icon = `${frontendConfig.cdnUrlInternal}/vink/images/header/v2/${
    active ? 'burger-cross' : 'burger'
  }.svg`;

  return (
    <button onClick={onClick} className="drop-down-button">
      <img src={icon} alt="meny" />
    </button>
  );
};

export default DropDownButton;
