export const sections: Record<string, string> = {
  'mat-og-drikke': 'Mat & Drikke',
  'film-og-serier': 'Film & Serier',
  'byliv-og-guider': 'Byliv & Guider',
};

export type SortConfig = 'relevance' | 'date' | 'grade';

export interface SectionMapping {
  sectionId: string;
  placeholder: string;
  sectionDescription: string;
  searchTitle: string;
  sorters: SortConfig[];
}

interface SectionMappings {
  [key: string]: SectionMapping;
}

export const sectionMappings: SectionMappings = {
  'mat-og-drikke': {
    sectionId: 'c015128f-6100-4ed5-b340-48f7fc5e6cd3',
    placeholder: 'Prøv indisk eller Bjørvika',
    sectionDescription:
      'Vink gir deg restaurantanmeldelser og guider deg til Oslos beste matopplevelser. Du kan lagre håndplukkede tips i lister slik at du enkelt finner dem tilbake når du trenger dem.',
    searchTitle: 'Mat & Drikke',
    sorters: ['relevance', 'grade'],
  },
  'film-og-serier': {
    sectionId: '39d2ceca-e249-4e69-b57a-e88f8489f56b',
    placeholder: 'Prøv Stranger Things eller action',
    sectionDescription:
      'Lurer du på hvilken film eller serie du skal se? Vink gir deg anmeldelser, anbefalinger og enkel mulighet til å lagre tips i lister.',
    searchTitle: 'Film & Serier',
    sorters: ['relevance', 'grade'],
  },
  'byliv-og-guider': {
    sectionId: 'a52f1182-3cef-4c0e-8b42-df7cde582640',
    placeholder: 'Prøv padeltennis eller kunst',
    sectionDescription:
      'Bade, gå på tur eller på date? Vink gir deg håndplukkede lister, tips og guider til de beste opplevelsene i Oslo og Marka. ',
    searchTitle: 'Byliv & Guider',
    sorters: ['relevance', 'date'],
  },
};

export const colorMappings = (section: string) => {
  switch (section) {
    case 'Restaurantguiden':
      return '#ffffa9';
    case 'Opplevelser':
      return '#d8ffb3';
    case 'Film og serier':
      return '#8dd1ff';
    default:
      return '';
  }
};

export const sectionTitleMappings = (section: string) => {
  switch (section) {
    case 'Restaurantguiden':
      return 'Mat & Drikke';
    case 'Opplevelser':
      return 'Byliv & Guider';
    case 'Film og serier':
      return 'Film & Serier';
    default:
      return '';
  }
};

export const defaultDescription =
  'Vink er en tjeneste fra Aftenposten som gir deg restaurantanmeldelser, filmanmeldelser, serieanmeldelser, samt håndplukkede lister, tips og guider til de beste opplevelsene i Oslo.';
