import { useState, useEffect } from 'react';

import type {
  Hotspot,
  ImageSize,
} from '../../../core/data-sources/content-components/types.js';

export const calculatePosition = (
  { x, y, width, height }: Hotspot,
  { width: imageWidth, height: imageHeight }: ImageSize,
) => {
  const hotspotCenter = x + width / 2;
  const yHotspotCenter = y + width / 2;
  const xCenterPercent = (hotspotCenter / imageWidth) * 100;
  const yCenterPercent = (yHotspotCenter / imageHeight) * 100;

  const percentOfImageHotspotted = (width / imageWidth) * 100;
  const yPercentOfImageHotspotted = (height / imageHeight) * 100;

  let xReturn = xCenterPercent;
  let yReturn = yCenterPercent;

  if (xCenterPercent > 50) {
    xReturn += (percentOfImageHotspotted / 100) * (xCenterPercent - 50);
  } else if (xCenterPercent < 50) {
    xReturn -= (percentOfImageHotspotted / 100) * (50 - xCenterPercent);
  }
  if (yCenterPercent > 50) {
    yReturn += (yPercentOfImageHotspotted / 100) * (yCenterPercent - 50);
  } else if (yCenterPercent < 50) {
    yReturn -= (yPercentOfImageHotspotted / 100) * (50 - yCenterPercent);
  }

  return [xReturn, yReturn];
};

export const useHotspotStyles = ({
  hotspot,
  size,
}: {
  hotspot?: Hotspot;
  size?: ImageSize;
}) => {
  const [hotspotStyles, setHotspotStyles] = useState({});

  useEffect(() => {
    if (!hotspot || !size) {
      return;
    }

    const [x, y] = calculatePosition(hotspot, size);
    setHotspotStyles({ objectPosition: `${x}% ${y}%` });
  }, [hotspot, size]);

  return hotspotStyles;
};
