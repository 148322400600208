import { useCallback, useState } from 'react';

import { console } from '../../../core/system/console/index.js';

type Updater<T> = (prevValue: T) => T;

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const initialize = (): T => {
    try {
      const item = localStorage.getItem(key);
      if (item && item !== 'undefined') {
        return JSON.parse(item) as T;
      }
    } catch {
      return initialValue;
    }

    return initialValue;
  };

  const [state, setState] = useState<T>(initialize);

  const setValue = useCallback(
    (valueOrUpdater: T | Updater<T>) => {
      try {
        if (typeof valueOrUpdater === 'function') {
          setState((prevState) => {
            const newValue = (valueOrUpdater as Updater<T>)(prevState);
            localStorage.setItem(key, JSON.stringify(newValue));

            return newValue;
          });
        } else {
          setState(valueOrUpdater);
          localStorage.setItem(key, JSON.stringify(valueOrUpdater));
        }
      } catch (error) {
        console.error(error);
      }
    },
    [key],
  );

  return [state, setValue] as const;
};
