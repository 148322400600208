import React from 'react';

import type { FrapiTeaserItemSized } from '../../../../types/frapi-types.js';

import TeaserGrade from './TeaserGrade.js';
import { useListsContext } from '../../saving/ListsProvider.js';
import { itemIsSaved } from '../../saving/utils.js';
import {
  isExperience,
  isGodt,
  isMovieSeries,
  isNews,
  isRestaurant,
} from '../../articles/helpers.js';
import TeaserTag from './TeaserTag.js';

const TeaserKicker: React.FC<FrapiTeaserItemSized> = (teaser) => {
  const { savedItems } = useListsContext();

  const isSaved = itemIsSaved(
    {
      id: teaser.id,
      title: teaser.title,
      slug: teaser.slug,
      kicker: teaser.kicker,
    },
    savedItems,
  );

  const classNames = [
    'kicker',
    isRestaurant(teaser) ? 'yellow' : '',
    isMovieSeries(teaser) ? 'blue' : '',
    isExperience(teaser) && teaser.hasGroups ? 'pink' : '',
    isGodt(teaser) ? 'godt' : '',
  ].join(' ');

  const defaultKickerText =
    isNews(teaser) || isExperience(teaser)
      ? teaser.hasGroups
        ? 'Guide'
        : 'Nyheter'
      : teaser.section;

  const smallMobile = teaser.mobile && teaser.size === 'small';

  return (
    <div className="kicker-wrapper">
      <div className="tag-grade">
        <div className={classNames}>{teaser.kicker || defaultKickerText}</div>
        {smallMobile &&
          (teaser.grade ? (
            <TeaserGrade grade={teaser.grade} />
          ) : (
            <TeaserTag tags={teaser.tags} section={teaser.section} />
          ))}
      </div>
      {!teaser.hideSaveButton && (
        <button
          className={`save-button ${isSaved ? 'saved' : ''}`}
          onClick={teaser.handleSave}
          type="button"
        />
      )}
    </div>
  );
};
export default TeaserKicker;
