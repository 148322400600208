import React from 'react';

import type { OptionsFromRequest } from '../../../../core/system/rendering/types.js';

import Chat from './chatBot/Chat.js';

type VinkBotWidgetProps = {
  options: OptionsFromRequest;
};

const VinkBotWidget: React.FC<VinkBotWidgetProps> = ({ options }) => {
  const { abTestValue } = options;

  const isValidAbTestValue = () => {
    // AB test value within 0-9 (10%)
    const parsedAbTestValue = parseInt(abTestValue ?? '', 10);

    return (
      !isNaN(parsedAbTestValue) &&
      parsedAbTestValue >= 0 &&
      parsedAbTestValue < 10
    );
  };

  return isValidAbTestValue() ? <Chat /> : null;
};

export default VinkBotWidget;
