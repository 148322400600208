import React from 'react';

import type { Chunk } from '../../../types/frapi-types.js';

import Teaser from '../teasers/Teaser.js';
import BundleHeader from './BundleHeader.js';
import BundleOutro from './BundleOutro.js';
import {
  constructBundleId,
  isManualBundle,
  shouldCompressBundle,
} from './utils.js';

const LIMIT = 5;

const BundleDesktop: React.FC<Chunk> = (bundle: Chunk) => {
  const bundleItems = bundle.items;
  const story = bundleItems[0]?.storyTag;

  const sizeRules = [
    { length: 5, bigArticles: [0] },
    { length: 4 },
    { length: 3, mediumArticles: [0, 1, 2] },
    { length: 2, bigArticles: [0, 1] },
    { length: 1, mediumArticles: [0] },
  ];

  const hasSizeRules = sizeRules.find((i) => i.length <= bundleItems.length);

  const isBig = (i: number) =>
    Number(hasSizeRules?.bigArticles?.indexOf(i)) >= 0;
  const isMedium = (i: number) =>
    Number(hasSizeRules?.mediumArticles?.indexOf(i)) >= 0;

  const isBundle = isManualBundle(bundle);
  const classNames = ['bundle', shouldCompressBundle(bundle) ? 'grid' : ''];

  return (
    <section key={`bundle-${bundle.id}`} className={classNames.join(' ')}>
      {story && isBundle && <BundleHeader story={story} />}
      <div className="bundle-teaser-container">
        <div className="bundle-teasers">
          {bundleItems.slice(0, LIMIT).map((bundleItem, i) => {
            const size = isBig(i) ? 'big' : isMedium(i) ? 'medium' : 'small';
            const item = { ...bundleItem, fullWidthTeaser: false };

            return (
              <Teaser
                key={bundleItem.id}
                {...item}
                size={size}
                index={bundle.index}
                positionInBundle={isBundle ? i + 1 : undefined}
                bundleId={isBundle ? constructBundleId(bundle) : undefined}
              />
            );
          })}
        </div>
      </div>
      {story && isBundle && <BundleOutro story={story} bundle={bundle} />}
    </section>
  );
};

export default BundleDesktop;
