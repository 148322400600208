export const makeHumanFriendlyString = (
  arr: string[],
  showUnique?: boolean,
): string => {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];

  const processedArr = showUnique ? [...new Set(arr)] : arr;

  if (processedArr.length === 1) return processedArr[0];

  const firsts = processedArr.slice(0, processedArr.length - 1);
  const last = processedArr[processedArr.length - 1];

  return firsts.join(', ') + ' og ' + last;
};
