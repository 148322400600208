import { useEffect } from 'react';

export const useAdjustAdvertoryTakeoverStyles = (
  isFrontpage?: boolean,
): void => {
  useEffect(() => {
    const adjustTakeoverStyles = () => {
      const container = document.querySelector<HTMLDivElement>(
        '.advertory-container-anchor',
      );
      const pageNavigation = document.querySelector<HTMLDivElement>(
        '.advertory-navigation-anchor',
      );
      const subscribeBar = document.querySelector<HTMLDivElement>(
        '.advertory-subscribe-bar-anchor',
      );

      if (container) {
        container.style.paddingTop = 'unset';
      }

      if (isFrontpage && pageNavigation) {
        pageNavigation.style.position = 'sticky';
        pageNavigation.style.transform = 'none';
      }

      if (subscribeBar) {
        subscribeBar.style.display = 'none';
      }
    };

    window.addEventListener('advertory:takeoverInView', adjustTakeoverStyles);

    return () =>
      window.removeEventListener(
        'advertory:takeoverInView',
        adjustTakeoverStyles,
      );
  }, []);
};
