import React from 'react';

type UserSearchedProps = {
  search: string;
};

const UserSearched: React.FC<UserSearchedProps> = ({ search }) => {
  return (
    <div className="user-searched">
      <p className="user-searched__text">
        Du søkte på: <span className="user-searched__value">{search}</span>
      </p>
    </div>
  );
};

export default UserSearched;
