import type {
  ByfunnFrapiArticle,
  ByfunnPromoArticle,
  FrapiTeaserItemSized,
  GodtTeasers,
  Recommendations,
  TagType,
} from '../../../types/frapi-types.js';
import type {
  VerticalFadeGalleryComponent,
  VideoAssetComponent,
} from '../../../../core/data-sources/content-components/types.js';
import type { Tag } from '../../../../core/data-sources/frapi/data-types.js';

import { isTextBlock } from '../../../../core/data-sources/content-components/type-guards.js';

type ArticleOrTeaser =
  | ByfunnFrapiArticle
  | FrapiTeaserItemSized
  | ByfunnPromoArticle;

export const getLead = (article: ByfunnFrapiArticle | ByfunnPromoArticle) => {
  const firstParagraph = article.lead?.paragraphs?.[0];

  if (firstParagraph && isTextBlock(firstParagraph)) {
    return firstParagraph.text.value;
  }

  return undefined;
};

export const isRestaurant = (article: ArticleOrTeaser) =>
  article.section === 'Restaurantguiden';
export const isRestaurantReview = (article: ArticleOrTeaser) =>
  isRestaurant(article) && !article.hasGroups;

export const isMovieSeries = (article: ArticleOrTeaser) =>
  article.section === 'Film og serier';
export const isMovieReview = (article: ArticleOrTeaser) =>
  isMovieSeries(article) && !article.hasGroups;

export const isExperience = (article: FrapiTeaserItemSized | ArticleOrTeaser) =>
  article.section === 'Opplevelser';

export const isGodt = (article: ArticleOrTeaser) =>
  article.section === 'godt.no';

export const isFeature = (article: ArticleOrTeaser) =>
  article.articleTemplate === 'feature';

export const isNews = (article: ArticleOrTeaser) =>
  article.articleTemplate === 'news';

export const getLoopingVideoIntro = (
  article: ByfunnFrapiArticle,
): VideoAssetComponent | null => {
  const firstComponent = article.components[0] as VerticalFadeGalleryComponent;

  if (
    firstComponent.type === 'gallery' &&
    firstComponent.presentationProperties?.intention === 'vertical-fade' &&
    firstComponent.components[0].type === 'video'
  ) {
    return firstComponent.components[0];
  }

  return null;
};

export const isReview = (article: ArticleOrTeaser) =>
  isRestaurantReview(article) || isMovieReview(article);

export const shouldDisplayAds = (article: ByfunnFrapiArticle) => {
  return (
    isRestaurant(article) || isMovieSeries(article) || isExperience(article)
  );
};

export const makeTagTitle = (title: string) => {
  const segments = title.split('/');
  const isPrice = segments[1] === 'pris';
  const tagTitle = segments.pop();

  if (isPrice && tagTitle) {
    return `Pris: ${tagTitle.toLowerCase()}`;
  }

  return tagTitle;
};

export const sectionMapping: Record<string, string> = {
  Restaurantguiden: 'mat-og-drikke',
  'Film og serier': 'film-og-serier',
  Opplevelser: 'byliv-og-guider',
};

export const filterTagsOnType = (
  tags: Tag[],
  tagType: TagType | TagType[],
  limit?: number,
) => {
  let filteredTags = tags;
  if (Array.isArray(tagType)) {
    filteredTags = filteredTags.filter((tag) => {
      const tagSet: Set<string> = new Set(tagType);
      const [, type] = tag.title.split('/');

      return tagSet.has(type) && tag.enabled;
    });
  } else {
    filteredTags = filteredTags.filter((tag) => {
      const [, type] = tag.title.split('/');

      return type === tagType && tag.enabled;
    });
  }
  if (limit) {
    filteredTags = filteredTags.slice(0, limit);
  }

  return filteredTags;
};

export const topSaveTags = (article: ArticleOrTeaser) => {
  const relevantTopTags = filterTagsOnType(
    article.tags,
    ['område', 'strømmetjeneste'],
    1,
  );

  return relevantTopTags.length > 0
    ? makeTagTitle(relevantTopTags[0].title)
    : article.section;
};

export const restSaveTags = (article: ArticleOrTeaser) =>
  filterTagsOnType(
    article.tags,
    ['format', 'hererdet', 'kjøkken', 'mattype', 'type'],
    3,
  )
    .map((t) => makeTagTitle(t.title))
    .join(', ');

export const formatRestaurantUrl = (url: string) => {
  return url.startsWith('www') ? `//${url}` : url;
};

export const transformGodtToFrapi = (
  godtTeasers: GodtTeasers,
): Recommendations => ({
  items: godtTeasers.items.map((godtTeaser) => ({
    ...godtTeaser,
    articleTemplate: 'automatic',
    access: {
      restrictions: ['open'],
    },
    tags: [],
    priceLevel: 'none',
    hasGroups: false,
  })),
});
