import React from 'react';

import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';
import NavigationLink from './NavigationLink.js';

const { cdnUrlInternal } = frontendConfig;

const NavigationSections: React.FC<{ activeSection?: string }> = ({
  activeSection,
}) => (
  <div className="section-links">
    <NavigationLink
      className="mat-og-drikke"
      location="/mat-og-drikke"
      name="Mat & Drikke"
      imgUrl={`${cdnUrlInternal}/vink/images/icons/sections/food-drinks.svg`}
      activeSection={activeSection}
    />
    <NavigationLink
      className="byliv-og-guider"
      location="/byliv-og-guider"
      name="Byliv & Guider"
      imgUrl={`${cdnUrlInternal}/vink/images/icons/sections/city-life-guide.svg`}
      activeSection={activeSection}
    />
    <NavigationLink
      className="film-og-serier"
      location="/film-og-serier"
      name="Film & Serier"
      imgUrl={`${cdnUrlInternal}/vink/images/icons/sections/movies-series.svg`}
      activeSection={activeSection}
    />
    <NavigationLink
      className="top-lists"
      location="/mat-og-drikke/restaurantguiden"
      name="Topplister"
      imgUrl={`${cdnUrlInternal}/vink/images/icons/sections/star.svg`}
      activeSection={activeSection}
    />
  </div>
);

export default NavigationSections;
