import React, { useState } from 'react';

import type { FrapiTeaserItemSized } from '../../../types/frapi-types.js';
import type { SaveListItem } from '../../../types/save-types.js';

import TeaserStandard from './TeaserStandard.js';
import TeaserFeature from './TeaserFeature.js';
import TeaserGodt from './TeaserGodt.js';
import TeaserWrapper from './TeaserWrapper.js';
import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { Save } from '../saving/Save.js';
import {
  isGodt,
  isReview,
  restSaveTags,
  topSaveTags,
} from '../articles/helpers.js';

const Teaser: React.FC<FrapiTeaserItemSized> = (teaser) => {
  const { trackClickUIElement } = usePulseContext();
  const listTeaser: SaveListItem = {
    id: teaser.id,
    title: isReview(teaser) ? teaser.kicker : teaser.title,
    imageId: teaser.image?.id,
    slug: teaser.slug,
    kicker: isReview(teaser)
      ? topSaveTags(teaser) || teaser.section
      : teaser.section,
    ...(isReview(teaser) && teaser.grade && { grade: teaser.grade }),
    ...(isReview(teaser) && { tags: restSaveTags(teaser) }),
    ...(teaser.imageUrl && { imageUrl: teaser.imageUrl }),
  };

  const [overlay, toggleOverlay] = useState({
    overlayVisible: false,
    itemToSave: listTeaser,
  });

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleOverlay();
  };

  const handleOverlay = () => {
    trackClickUIElement({
      object: {
        id: 'frontpage:save-button-teaser',
        name: 'Save button in teaser',
        elementType: 'Button',
      },
    }).finally(() => {
      toggleOverlay({
        overlayVisible: !overlay.overlayVisible,
        itemToSave: listTeaser,
      });
    });
  };

  let component;
  const isFeature = teaser.fullWidthTeaser;

  if (isGodt(teaser)) {
    component = <TeaserGodt {...teaser} handleSave={handleSave} />;
  } else if (isFeature) {
    component = <TeaserFeature {...teaser} />;
  } else {
    component = <TeaserStandard {...teaser} handleSave={handleSave} />;
  }

  const classNames = [
    'teaser',
    teaser.size,
    isFeature ? 'feature' : '',
    teaser.teaserWithIllustration ? 'illustration' : '',
    isGodt(teaser) ? 'godt-teaser' : '',
  ].join(' ');

  return (
    <>
      {overlay.overlayVisible && (
        <Save handleOverlay={handleOverlay} item={overlay.itemToSave} />
      )}
      <TeaserWrapper
        classNames={classNames}
        teaser={teaser}
        isMobile={teaser.mobile}
        bundleId={teaser.bundleId}
        link={teaser.link}
        {...(isGodt(teaser) && { externalSource: 'godt.no' })}
      >
        {component}
      </TeaserWrapper>
    </>
  );
};
export default Teaser;
