import React, { useState } from 'react';

import type { SaveProps } from '../../../types/save-types.js';

import { LoginWall } from '../commonComponents/LoginWall.js';
import { Overlay } from '../commonComponents/Overlay.js';
import { NewList } from './NewList.js';
import { SaveInList } from './SaveInList.js';
import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';

export const Save: React.FC<SaveProps> = ({ handleOverlay, item }) => {
  const [newList, toggleNewList] = useState(false);
  const { trackClickUIElement } = usePulseContext();

  const handleToggleNewList = () => {
    trackClickUIElement({
      object: {
        id: 'save:new-list-button',
        name: 'Button in save overlay for creating new lists',
        elementType: 'Button',
      },
    }).finally(() => toggleNewList(!newList));
  };

  return (
    <LoginWall handleOverlay={handleOverlay}>
      <Overlay
        className="save-overlay"
        handleOverlay={handleOverlay}
        title="Velg liste"
      >
        <div className="list-options">
          {newList && (
            <NewList
              item={item}
              handleOverlay={handleOverlay}
              handleCancel={handleToggleNewList}
            />
          )}
          <button className="create-new-list" onClick={handleToggleNewList}>
            Lag ny liste
          </button>
          <SaveInList item={item} handleOverlay={handleOverlay} />
        </div>
      </Overlay>
    </LoginWall>
  );
};
