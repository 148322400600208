import {
  Takeover,
  Sponsorstripe,
} from '@sch-inventory/advertory/sites/aftenposten/index.js';
import React, { useEffect } from 'react';

import type { OptionsFromRequest } from '../../../../core/system/rendering/types.js';
import type { ByfunnFront } from '../../../types/frapi-types.js';

import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { withByfunnPulse, trackFrontpageView } from '../../../pulse/index.js';
import BundleDesktop from '../../components/bundles/BundleDesktop.js';
import BundleMobile from '../../components/bundles/BundleMobile.js';
import Chat from '../../components/chatBot/Chat.js';
import Footer from '../../components/commonComponents/Footer.js';
import Navigation from '../../components/commonComponents/Navigation/Navigation.js';
import { DataController } from '../../components/commonComponents/DataController.js';
import Teaser from '../../components/teasers/Teaser.js';
import { splitToChunks } from './frontHelpers.js';
import { renderWidget } from './RenderWidget.js';
import { desktopWidgetConfig, mobileWidgetConfig } from './widgetConfig.js';
import Ad from '../../components/ads/Ad/Ad.js';
import { insertAdsPlacements } from '../../components/ads/Ad/helpers.js';
import { useSessionData } from '../../../../../public-src/core/js/schibsted-account/react.server.js';
import { ListsProvider } from '../../components/saving/ListsProvider.js';
import useInitializeAds from '../../hooks/useInitAds.js';
import { shouldCompressBundle } from '../../components/bundles/utils.js';
import PWAPopup from '../../components/commonComponents/PWAPopup.js';
import { usePWAInstallPrompt } from '../../hooks/usePWAInstallPrompt.js';
import { useAdjustAdvertoryTakeoverStyles } from '../../hooks/useAdjustAdvertoryTakeoverStyles.js';
import usePersonalization from '../../hooks/usePersonalization.js';

type FrontpageProps = {
  response: ByfunnFront;
  options: OptionsFromRequest;
  isMobile: boolean;
  enableChatbot: boolean;
  isHermesApp?: boolean;
};

const Frontpage: React.FC<FrontpageProps> = ({
  response,
  options,
  isMobile,
  enableChatbot,
  isHermesApp,
}) => {
  const { siteVersion } = options;
  const session = useSessionData();
  const { registerViewElementTracking, trackViewListing, getEnvironmentId } =
    usePulseContext();

  const {
    shouldShowPrompt,
    incrementVisitCounter,
    incrementPWACloseCounter,
    setPWADate,
    setPWAInstalled,
  } = usePWAInstallPrompt();

  useEffect(() => {
    incrementVisitCounter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAdjustAdvertoryTakeoverStyles(true);

  const handleClosePWAOverlay = async () => {
    setPWADate();
    incrementPWACloseCounter();
  };

  const personalization = usePersonalization(response);

  useEffect(() => {
    trackFrontpageView(trackViewListing, personalization);

    registerViewElementTracking({
      object: {
        page: {
          id: 'frontpage',
          type: 'Listing',
        },
      },
    });
  }, [trackViewListing, personalization, registerViewElementTracking]);

  useInitializeAds(getEnvironmentId, session, siteVersion);

  const chunks = splitToChunks(response.feed.items);

  // insert widgets
  const widgets = isMobile ? mobileWidgetConfig : desktopWidgetConfig;
  widgets.map((w) => {
    chunks.splice(w.position, 0, { type: 'widget', component: w.widget });
  });

  const chunksWithAds = insertAdsPlacements(
    isMobile,
    chunks,
    'frontpage',
    'frontpage',
  );

  let mobileRows = 1;

  return (
    <ListsProvider>
      <Takeover placementId="takeover" />
      <div className={`${siteVersion}-front advertory-container-anchor`}>
        <Navigation fullWidth={!isMobile} isSticky isHermesApp={isHermesApp} />

        <Sponsorstripe placementId="sponsorstripe" />

        {shouldShowPrompt && (
          <PWAPopup
            handleClose={handleClosePWAOverlay}
            setPWAInstalled={setPWAInstalled}
            isIOS
          />
        )}

        {chunksWithAds.map((c, i): React.ReactNode => {
          const index = isMobile && i > 0 ? mobileRows : i + 1;

          switch (c.type) {
            case 'teaser':
              if (c.fullWidthTeaser) {
                mobileRows++;

                return <Teaser key={c.id} {...c} index={index} />;
              }

              return <></>;
            case 'widget':
              mobileRows++;

              return renderWidget(c, isMobile, index, response, options);
            case 'ad':
              mobileRows++;

              return (
                <Ad
                  key={`Ad-${c.id}`}
                  id={c.id}
                  cssClass={c.cssClass}
                  isMobile={isMobile}
                />
              );
            case 'chunk':
            case 'bundle':
              mobileRows += shouldCompressBundle(c, isMobile)
                ? 2
                : c.items.length;

              return isMobile ? (
                <BundleMobile {...c} key={c.id} index={index} />
              ) : (
                <BundleDesktop {...c} key={c.id} index={index} />
              );
            default:
              return <></>;
          }
        })}
        {enableChatbot && <Chat isMobile={isMobile} />}
        <Footer isMobile={isMobile} />
        <DataController />
      </div>
    </ListsProvider>
  );
};

const FrontpageWithPulse = withByfunnPulse(Frontpage);

export { FrontpageWithPulse as Frontpage };
