import type { SaveList, SaveListItem } from '../../../types/save-types.js';

export const DEFAULT_LIST_NAME = 'Mine Vink';

export const itemIsSaved = (item: SaveListItem, savedItems: SaveList[]) => {
  return (
    savedItems
      .map((s) => s.content)
      .flat()
      .map((s) => s.id)
      .indexOf(item.id) >= 0
  );
};

export const listExists = (listName: string, savedItems: SaveList[]) => {
  return savedItems.map((s) => s.name).indexOf(listName) >= 0;
};

export const getHeartAsset = (item: SaveListItem, savedItems: SaveList[]) =>
  `https://cdn.aftenposten.no/vink/images/icons/heart${
    itemIsSaved(item, savedItems) ? '-saved' : ''
  }.svg`;

export const sortSavedItems = (savedItems: SaveList[]) =>
  savedItems.slice().sort((a, b) => {
    // mine vink should come first
    if (a.name === 'Mine Vink') {
      return -1;
    } else if (b.name === 'Mine Vink') {
      return 1;
    }

    // sort by date
    if (a.date && b.date) {
      return b.date - a.date;
    }

    // leftovers: sort by the order they come in
    return -1;
  });

export const canShare = (navigator: Navigator) =>
  navigator.canShare || navigator.clipboard;
export const listDataToShare = (window: Window, list: SaveList) => {
  const urifragment = window.btoa(encodeURIComponent(JSON.stringify(list)));
  const thumbnail = list.content.at(-1)?.imageId || 'default';

  return {
    title: 'Del listen',
    text: `Du deler ${list.name}`,
    url: `${window.location.origin}/delte-vink/${list.name}-${thumbnail}/#${urifragment}`,
  };
};
