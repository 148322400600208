import React from 'react';

import { LinkWithPulse } from '../../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';
import NavigationSections from './NavigationSections.js';
import NavigationLink from './NavigationLink.js';
import NavigationLoginButton from './NavigationLoginButton.js';
import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';

interface NavigationMenuProps {
  hasSession: boolean;
  isMobile: boolean;
  showTcf: boolean;
  activeSection?: string;
  isHermesApp?: boolean;
}

const { cdnUrlInternal } = frontendConfig;

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  hasSession,
  isMobile,
  showTcf,
  isHermesApp,
  activeSection,
}) => (
  <div className="menu">
    {isMobile && <NavigationSections activeSection={activeSection} />}
    <NavigationLoginButton
      hasSession={hasSession}
      activeSection={activeSection}
    />
    {!isHermesApp && (
      <>
        <NavigationLink
          className="aftenposten"
          location="https://www.ap.no"
          name="Aftenposten"
          imgUrl={`${cdnUrlInternal}/vink/images/header/v2/ap.svg`}
          activeSection={activeSection}
        />
        <NavigationLink
          className="aftenposten godt"
          location="https://www.godt.no"
          name="Godt"
          imgUrl={`${cdnUrlInternal}/vink/images/header/v2/godt.svg`}
          activeSection={activeSection}
        />
        {showTcf && (
          <LinkWithPulse
            className="aftenposten samtykke link"
            id="navigation:Samtykkeinstillinger"
            name="Samtykkeinstillinger"
            href=""
            preventDefaultAction={true}
            onResolveAction={() => {
              window._tcf_?.showPrivacyManager();
            }}
          >
            <div className="icon">
              <img
                src={`${cdnUrlInternal}/ap/images/functional_icons/settings/settings.svg`}
                alt="Samtykkeinstillinger-button"
              />
            </div>
            <div className="section-title">Samtykkeinstillinger</div>
          </LinkWithPulse>
        )}
      </>
    )}
  </div>
);
export default NavigationMenu;
