import React from 'react';

import { frontendConfig } from '../../../../core/system/config/frontend.server.js';

const { cdnUrlInternal } = frontendConfig;

type FooterProps = {
  isMobile?: boolean;
};

const Footer: React.FC<FooterProps> = ({ isMobile }) => {
  const icons = (
    <div className="icon-wrapper">
      <a href="https://www.aftenposten.no/" target="_blank" rel="noreferrer">
        <img
          src={`${cdnUrlInternal}/vink/images/footer/ap-logo-white.svg`}
          alt="aftenposten"
        />
      </a>

      <a
        href="https://www.nored.no/Redaktoeransvar/Redaktoerplakaten"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={`${cdnUrlInternal}/vink/images/footer/ansvarsmerket-white.svg`}
          alt="redaktøransvar"
        />
      </a>
    </div>
  );

  const tips = (
    <span className="footer_tips">
      <a href="mailto:vink@aftenposten.no">Tips oss</a>
    </span>
  );

  return (
    <div className="footer">
      <div className="footer_container">
        <div className="footer_section logo">
          <img
            className="logo"
            src={`${cdnUrlInternal}/vink/images/logo.svg`}
            alt="vink"
          />
          {isMobile && tips}
        </div>
        <div className="footer_section">
          <span>
            Redaktør for Vink: <b className="yellow">Cecilie Asker</b>
          </span>
          <span>
            Tilsynsorgan: <b>Medietilsynet</b>
          </span>
          <br />
          <a
            href="https://kundeportal.aftenposten.no/kundeservice/"
            target="_blank"
            rel="noreferrer"
          >
            Kundeservice
          </a>
          <a
            href="https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/"
            target="_blank"
            rel="noreferrer"
          >
            Personvernerklæring
          </a>
          <a
            href="https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/"
            target="_blank"
            rel="noreferrer"
          >
            Cookie-erklæring
          </a>
          <br />
          <div className="contact">
            <a
              href="http://goo.gl/maps/XgFjg"
              target="_blank"
              rel="noreferrer noopener"
            >
              Akersgata 55, 0180 Oslo
            </a>
            <a href="tel:22863000">Tlf. 22 86 30 00</a>
            <a href="mailto:vink@aftenposten.no">vink@aftenposten.no</a>
          </div>
        </div>

        <div className="footer_section first">
          {icons}
          <p>
            Alt innhold er opphavsrettslig beskyttet. © Aftenposten.
            Aftenposten arbeider etter Vær Varsom-plakatens regler for god
            presseskikk. Aftenposten har ikke ansvar for innhold på eksterne
            nettsider som det lenkes til.
          </p>
        </div>
        {!isMobile && tips}
      </div>
    </div>
  );
};

export default Footer;
