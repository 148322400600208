import React, { useState } from 'react';

import type { MessageType } from './chatBotComponents/types.js';

import MessageDesc from './chatBotComponents/MessageDesc.js';
import ChatInput from './chatBotComponents/ChatInput.js';
import TagSuggestion from './chatBotComponents/TagSuggestion.js';
import UserSearched from './chatBotComponents/UserSearched.js';
import RestartButton from './chatBotComponents/RestartButton.js';
import RateChat from './chatBotComponents/RateChat.js';
import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';
import {
  getBrowserCookieValue,
  setBrowserCookie,
} from '../../../../../aftenposten/helpers/cookie.js';
import { usePulseContext } from '../../../../../core/mandatory-integrations/pulse/PulseProvider.js';

const COOKIE_KEY = 'viewed-vink-o-mat';
const DAYS_UNTIL_EXPIRATION = 7;

const getBotResponse = (message: string): MessageType => {
  switch (message) {
    case 'Hvilke steder har billige burgere?':
      return {
        header: 'Jeg foreslår at du sjekker ut disse stedene!',
        sender: 'bot',
        recommendations: [
          {
            id: '8JxKXW',
            title: 'Doug’s Hamburgers',
            grade: 5,
            area: 'Vulkan',
          },
          {
            id: 'LlGGo1',
            title: 'Render Burger',
            grade: 5,
            area: 'Torshov',
          },
          {
            id: 'LlbqV4',
            title: 'Bristol Pub',
            grade: 5,
            area: 'Sentrum',
          },
        ],
        descriptions: [
          'Vil du ha en knallgod smashburger, sjekker du ut Doug’s hamburgers på Mathallen.',
          '210 kroner for Oslos beste burger? Ja, Render burger vant vår kåring av Oslos beste burger i 2023. Deres «Oslohoma smah» med grillet løk og dobbel dose ost er en saftig nytelse. Sleng på deilige fries til 59 kroner, og januar er slettes ikke så verst.',
          'Bristol pub er et godt sted å dra før man skal på teater, konsert eller kino i sentrum. Her får du kjapp og god mat og burgeren gir deg den umamibomben du trenger etter en lang dag på jobben.',
        ],
      };
    case 'Beste italienske i Oslo?':
      return {
        header: 'Jeg foreslår at du sjekker ut disse stedene!',
        sender: 'bot',
        recommendations: [
          {
            id: 'bgkG23',
            title: 'Ansjosen',
            grade: 5,
            area: 'St. Hanshaugen',
          },
          {
            id: 'RG8bnA',
            title: 'Vesuvio',
            grade: 5,
            area: 'St. Hanshaugen',
          },
          {
            id: 'EQa5AG',
            title: 'Bono',
            grade: 5,
            area: 'Frogner',
          },
        ],
        descriptions: [
          'Denne nabolagsrestauranten er som en varm klem. Her får du ingen klisjéfylte italienske spillelister på høyttalerne. Stedet er bare en skikkelig koselig og «nedpå» nabolagsrestaurant med ordentlig digg kvalitetsmat.',
          'Den italienske nabolagsperlen Vesuvio befinner seg helt i toppsjiktet i Oslos pizzaflora.',
          'Det hemmelige pastaparadiset. Lykkelig er den som får servert spagetti fra Monicas kjøkken.',
        ],
      };
    default:
      return {
        header: 'Hei!',
        sender: 'bot',
        detail:
          'Så kult at du testet Vink-O-maten. Akkurat nå er vi nysgjerrige på om det er interesse for denne tjenesten og kan dessverre ikke vise deg svar. Vi setter stor pris på at du sjekker ut nye funksjoner på Vink.',
      };
  }
};

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [inputText, setInputText] = useState('');
  const [isInitialView, setIsInitialView] = useState(true);

  const { trackClickUIElement } = usePulseContext();

  const getViewedVinkOMatCookie = () => {
    const cookie = getBrowserCookieValue(COOKIE_KEY);

    return cookie ? JSON.parse(cookie) === true : false;
  };

  const viewedVinkOMatCookie = getViewedVinkOMatCookie();

  if (viewedVinkOMatCookie) return null;

  const generateExpirationDate = () => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + DAYS_UNTIL_EXPIRATION);

    return expirationDate;
  };

  const updateVinkOMatCookie = () => {
    setBrowserCookie(
      COOKIE_KEY,
      JSON.stringify(true),
      generateExpirationDate(),
    );
  };

  const sendMessage = async (message: string, sender: 'user' | 'bot') => {
    const newMessage = { header: message, sender };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputText('');
    setIsInitialView(false);

    trackClickUIElement({
      object: {
        id: 'vink-o-mat-message',
        name: `Message sent: ${message}`,
        elementType: 'Widget',
      },
    });

    // Hardcode responses for fake-door
    const botResponse: MessageType = getBotResponse(message);

    setMessages((prevMessages) => [...prevMessages, botResponse]);
  };

  const restartChat = () => {
    setMessages([]);
    setIsInitialView(true);
    updateVinkOMatCookie();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setInputText(value);

    // Resize textarea height as in changes in length
    const textarea = e.target;
    textarea.style.height = '28px'; // Reset to initial height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set to content height
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') sendMessage(inputText, 'user');
  };

  const userSearch = messages.find((msg) => msg.sender === 'user')?.header;
  const botMessages = messages.filter((msg) => msg.sender === 'bot');

  return (
    <div className="vink-o-mat" data-pulse-entity-id="vink-o-mat">
      <div className="chat-window">
        <div className="chat-header">
          <img
            className="cutlery__icon"
            src={`${frontendConfig.cdnUrlInternal}/vink/images/icons/cutlery.svg`}
            alt="Cutlery Icon"
          />
          <h3 className="header-title">Vink-O-Mat</h3>
        </div>
        {isInitialView ? (
          <>
            <p className="chat-intro">
              Usikker på hvor du skal spise i kveld? Spør AI-botten vår om
              hjelp!
            </p>
            <TagSuggestion sendMessage={sendMessage} />
            <ChatInput
              inputText={inputText}
              handleInputChange={handleInputChange}
              handleKeyDown={handleKeyDown}
              sendMessage={sendMessage}
            />
          </>
        ) : (
          <>
            {userSearch && <UserSearched search={userSearch} />}
            <div className="messages">
              {botMessages.map((message, index) => (
                <React.Fragment key={index}>
                  <div className="message-container bot">
                    <MessageDesc
                      header={message.header}
                      detail={message.detail}
                      recommendations={message.recommendations}
                      descriptions={message.descriptions}
                    />
                  </div>
                  {message.descriptions && <RateChat />}
                </React.Fragment>
              ))}
            </div>
            <RestartButton onClick={restartChat} />
          </>
        )}
      </div>
    </div>
  );
};

export default Chat;
