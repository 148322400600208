import React from 'react';

import type { ImageAsset } from '../../../../../core/data-sources/content-components/types.js';

import { calculatePosition } from '../../../hooks/useHotspot.js';
import { getImageUrl } from '../../commonComponents/imageUtils.js';

interface ImageProps {
  imageAsset: ImageAsset;
  title: string;
  width?: number;
  height?: number;
  lazyLoad?: boolean;
  size?: 'small' | 'medium' | 'big';
  imageUrl?: string;
}

const Image: React.FC<ImageProps> = ({
  imageAsset,
  title,
  width = 576,
  height = 384,
  lazyLoad = false,
  imageUrl,
}) => {
  let src;
  if (imageUrl) {
    src = imageUrl;
  } else {
    src = getImageUrl(imageAsset.id, width, height);
  }

  let hotspotStyles;
  if (imageAsset.hotspot && imageAsset.size) {
    const [x, y] = calculatePosition(imageAsset.hotspot, imageAsset.size);
    hotspotStyles = {
      objectPosition: `${x}% ${y}% !important`,
    };
  }

  return lazyLoad ? (
    <>
      <noscript>
        <img className={`no-script image`} src={src} alt={title} />
      </noscript>
      <img
        className={`b-lazy image`}
        data-src={src}
        alt={title}
        loading="lazy"
        decoding="async"
        style={hotspotStyles}
      />
    </>
  ) : (
    <img
      className="image"
      src={src}
      alt={title}
      loading="eager"
      decoding="auto"
      style={hotspotStyles}
    />
  );
};

export default Image;
