import { useState, useEffect } from 'react';

import { useLocalStorage } from './useLocalStorage.js';

const DAYS_TO_REPROMPT = 10;
const MAX_CLOSE_COUNT = 3;
const MINIMUM_NUMBER_OF_VISITS = 3;

type PwaData = {
  isInstalled: boolean;
  lastPromptDate: number | null;
  closeCount: number;
  frontpageVisits: number;
};

export const usePWAInstallPrompt = () => {
  const [shouldShowPrompt, setShouldShowPrompt] = useState(false);

  const [pwaData, setPwaData] = useLocalStorage<PwaData>('pwaData', {
    isInstalled: false,
    lastPromptDate: null,
    closeCount: 0,
    frontpageVisits: 0,
  });

  useEffect(() => {
    const isPWASupported = 'serviceWorker' in navigator;
    const isPWAOpen = window.matchMedia('(display-mode: standalone)').matches;
    const isPWAInstalled = pwaData.isInstalled;

    const ua = window.navigator.userAgent;
    const isMobile =
      /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        ua,
      );
    const isIOS = /(iPhone)/i.test(ua);

    if (
      !isMobile ||
      !isPWASupported ||
      !isIOS ||
      isPWAOpen ||
      isPWAInstalled ||
      pwaData.closeCount >= MAX_CLOSE_COUNT ||
      pwaData.frontpageVisits < MINIMUM_NUMBER_OF_VISITS
    ) {
      setShouldShowPrompt(false);

      return;
    }

    const daysSinceLastPrompt = pwaData.lastPromptDate
      ? (Date.now() - pwaData.lastPromptDate) / (1000 * 60 * 60 * 24)
      : null;

    const shouldRePrompt =
      daysSinceLastPrompt === null || daysSinceLastPrompt > DAYS_TO_REPROMPT;

    setShouldShowPrompt(shouldRePrompt);
  }, [pwaData]);

  const setPWADate = () => {
    setPwaData((prevData) => ({
      ...prevData,
      lastPromptDate: Date.now(),
    }));
  };

  const setPWAInstalled = () => {
    setPwaData({
      ...pwaData,
      isInstalled: true,
    });
  };

  const incrementPWACloseCounter = () => {
    setPwaData((prevData) => ({
      ...prevData,
      closeCount: prevData.closeCount + 1,
    }));
  };

  const incrementVisitCounter = () => {
    setPwaData((prevData) => ({
      ...prevData,
      frontpageVisits: prevData.frontpageVisits + 1,
    }));
  };

  return {
    shouldShowPrompt,
    setPWADate,
    setPWAInstalled,
    incrementPWACloseCounter,
    incrementVisitCounter,
  };
};
