import React from 'react';

import type { Chunk, Story } from '../../../types/frapi-types.js';

interface BundleOutroProps {
  story: Story;
  bundle: Chunk;
}

const BundleOutro: React.FC<BundleOutroProps> = ({ story, bundle }) => {
  const linkToStoryPage = bundle.items.every(
    (i) => i.storyTag?.id === story.id,
  );

  return (
    <div className={`bundle-outro ${!linkToStoryPage ? 'no-link' : ''}`}>
      {linkToStoryPage && (
        <a className="bundle-outro-link" href={`/story/${story?.id}`}>
          {`Alle saker om ${story.title}`}
        </a>
      )}
    </div>
  );
};

export default BundleOutro;
