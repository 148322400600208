import React from 'react';

const suggestions = [
  'Hvilke steder har billige burgere?',
  'Mat i nærheten av meg',
  'Beste italienske i Oslo?',
];

type TagSuggestionProps = {
  sendMessage: (text: string, sender: 'user' | 'bot') => void;
};

const TagSuggestion: React.FC<TagSuggestionProps> = ({ sendMessage }) => (
  <div className="suggestions-wrapper">
    {suggestions.map((suggestion, idx) => (
      <button
        key={idx}
        className="suggestions-wrapper__suggestion"
        onClick={() => sendMessage(suggestion, 'user')}
      >
        {suggestion}
      </button>
    ))}
  </div>
);

export default TagSuggestion;
