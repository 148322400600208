import type { WrappedTeaser } from './TeaserWrapper.js';

import { accessLevel, accessLevelName } from '../../../../core/utils/access.js';

export interface PulseMeta {
  (teaser: WrappedTeaser, link?: string): BaseMeta;
}

type BaseMeta = {
  'data-pulse-entity-type': string;
  'data-pulse-entity-id': string;
  'data-pulse-entity-target-url': string;
  'data-pulse-entity-name': string;
  'data-pulse-teaser-image': string;
  'data-pulse-entity-target-name': string;
  'data-pulse-position': number;
  'data-pulse-source'?: string;
  'data-pulse-access-level'?: string;
  'data-pulse-access-level-name'?: string;
  'data-pulse-position-in-bundle'?: number;
  'data-pulse-bundle-id'?: string;
};

export const getPulseMeta: PulseMeta = (teaser, link) => {
  const baseMeta: BaseMeta = {
    'data-pulse-entity-type': 'Teaser',
    'data-pulse-entity-id': teaser.id,
    'data-pulse-entity-target-name':
      'title' in teaser ? teaser.title : teaser.properties.title,
    'data-pulse-position': teaser.index ?? 0,
    'data-pulse-entity-target-url': link || '',
    'data-pulse-entity-name':
      'title' in teaser ? teaser.title : teaser.properties.title,
    'data-pulse-teaser-image':
      'properties' in teaser
        ? teaser.properties.image
        : teaser.imageId
          ? teaser.imageId
          : '',
  };

  if ('src' in teaser) {
    baseMeta['data-pulse-source'] = teaser.src;
  }

  if ('access' in teaser) {
    baseMeta['data-pulse-access-level'] = accessLevel(teaser.access);
    baseMeta['data-pulse-access-level-name'] = accessLevelName(teaser.access);
  }

  if ('positionInBundle' in teaser) {
    baseMeta['data-pulse-position-in-bundle'] = teaser.positionInBundle;
  }

  if ('bundleId' in teaser) {
    baseMeta['data-pulse-bundle-id'] = teaser.bundleId;
  }

  return baseMeta;
};
