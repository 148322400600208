import { useMemo } from 'react';

import type { CuratePulseTrackingCustomProperties } from '../../../core/utils/types.js';
import type { ByfunnFront } from '../../types/frapi-types.js';

const usePersonalization = (
  response: ByfunnFront,
): CuratePulseTrackingCustomProperties => {
  const { id } = response.feed.properties;
  const { removeOnRead, removeOnImpressed, personalizedResults } =
    response.feed.personalization;

  const personalization: CuratePulseTrackingCustomProperties = useMemo(
    () => ({
      variant: id,
      removeOnRead,
      removeOnImpressed,
      personalizedResults,
    }),
    [id, removeOnRead, removeOnImpressed, personalizedResults],
  );

  return personalization;
};

export default usePersonalization;
