import React, { useState } from 'react';

import type { SaveList, SaveProps } from '../../../types/save-types.js';

import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { useListsContext } from './ListsProvider.js';

interface NewListProps extends SaveProps {
  handleCancel?: () => void;
}
export const NewList: React.FC<NewListProps> = ({
  item,
  handleOverlay,
  handleCancel,
}) => {
  const { trackClickUIElement } = usePulseContext();
  const [listName, setListName] = useState('');
  const { savedItems, saveItem } = useListsContext();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setListName(e.target.value);
  };

  const newList = {
    name: listName,
    content: item ? [item] : [],
    date: new Date().getTime(),
  };
  const newListExists = (l: SaveList) => l.name === listName;

  const handleSaveItem = () => {
    saveItem(newList, handleOverlay);
  };

  const handleCreateList = () => {
    trackClickUIElement({
      object: {
        id: 'save:submit-new-list-button',
        name: 'Submit button for new lists',
        elementType: 'Button',
      },
    }).finally(() => handleSaveItem());
  };

  return (
    <div className="create-list-input">
      {handleCancel && <div className="create-list-title">Ny liste</div>}
      <input
        type="text"
        name="name"
        onChange={handleOnChange}
        value={listName}
        placeholder="Gi listen et navn"
      ></input>
      <div className="create-list-button-container">
        <button
          className="create-list-button"
          onClick={handleCreateList}
          disabled={savedItems.some(newListExists) || listName === ''}
        >
          Opprett ny liste
        </button>
        {handleCancel && (
          <button className="create-list-cancel-button" onClick={handleCancel}>
            Avbryt
          </button>
        )}
      </div>
    </div>
  );
};
