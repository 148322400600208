import React, { useEffect, useRef, useState } from 'react';

import useDimensions from '../../hooks/useDimensions.js';

interface CarouselProps {
  children: React.ReactNode[];
}

const DEF_ELEMENT_WIDTH = 256;

const Carousel = ({ children }: CarouselProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [childWidth, setChildWidth] = useState(0);
  const [distance, setDistance] = useState(0);
  const [totalInViewport, setTotalInViewport] = useState(0);
  const [viewed, setViewed] = useState(0);

  const [parentRef, parentSize] = useDimensions();

  useEffect(() => {
    const current = containerRef.current;
    const childNode = current?.children[0];

    // setChildWidth(current?.children[0].clientWidth || DEF_ELEMENT_WIDTH);
    setChildWidth(
      (childNode?.clientWidth || DEF_ELEMENT_WIDTH) +
        (childNode
          ? parseInt(window.getComputedStyle(childNode).marginRight)
          : 0),
    );
  }, [containerRef, children]);

  useEffect(() => {
    setTotalInViewport(Math.floor(parentSize.width / childWidth));
  }, [childWidth, containerRef, parentSize]);

  const handlePrev = () => {
    setViewed(viewed - totalInViewport);
    setDistance(distance + childWidth * totalInViewport);
  };

  const handleNext = () => {
    if (viewed + totalInViewport < children.length) {
      setViewed(viewed + totalInViewport);
      setDistance(-(viewed + totalInViewport) * childWidth);
    } else {
      // go to the start of the widget
      setViewed(0);
      setDistance(0);
    }
  };

  const slideProps = {
    style: {
      transform: `translate3d(${distance}px, 0, 0)`,
      display: 'flex',
      transition: 'all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
    },
  };

  const hasMoreButton = Boolean(
    totalInViewport && children.length > totalInViewport,
  );

  return (
    <div ref={parentRef} className="carousel">
      <div className="buttons-container">
        {distance < 0 && <button className="arrow-back" onClick={handlePrev} />}
        {hasMoreButton && (
          <button className="arrow-forward" onClick={handleNext} />
        )}
      </div>
      <div className="children" ref={containerRef} {...slideProps}>
        {children}
      </div>
    </div>
  );
};

export default Carousel;
