import { Overlay } from '@vgtv/stories-overlay';
import { useEffect } from 'react';

import { frontendConfig } from '../../../../core/system/config/frontend.server.js';

type Options = {
  preloadItems?: string[];
};

// This needs to be outside the hook else the iframe instance will be null
const overlay = new Overlay({
  baseUrl: `${frontendConfig.accountSdkConfig.redirectUriDomain}/stories`,
});

export const useReelsOverlay = ({ preloadItems }: Options) => {
  useEffect(() => {
    overlay.init();

    if (preloadItems) {
      overlay.preloadItems(preloadItems);
    }

    let titleToRestore = document.title;

    const closeOverlay = () => {
      const { pathname } = new URL(location.href);

      if (pathname.match(/\/stories\/\d+/)) {
        overlay.openItem(location.href);
      } else {
        overlay.closeOverlay();
      }
    };

    window.addEventListener('popstate', closeOverlay);

    overlay.addEventListener('overlay/open', () => {
      titleToRestore = document.title;
    });

    overlay.addEventListener('overlay/close', () => {
      document.title = titleToRestore;
    });

    overlay.addEventListener('overlay/state', ({ detail }) => {
      const { path, title } = detail;

      window.history.replaceState({}, '', path);
      document.title = title;
    });

    overlay.addEventListener('overlay/go-back', () => {
      history.back();
    });

    return () => {
      window.removeEventListener('popstate', closeOverlay);
      overlay.destroy();
    };
  }, []);

  const openReel = async (url: string) => {
    const { pathname, search } = new URL(url);
    const path = pathname + search;

    const success = await overlay.openItem(url);

    if (success) {
      history.pushState({}, '', path);
    } else {
      // if the iframe didn't load yet/it failed to fetch an asset, then proceed to target url
      window.location.href = url;
    }
  };

  return {
    openReel,
  };
};
