import React from 'react';

import { frontendConfig } from '../../../../../../core/system/config/frontend.server.js';

type RestartButtonProps = {
  onClick: () => void;
};

const RestartButton: React.FC<RestartButtonProps> = ({ onClick }) => {
  return (
    <div className="restart">
      <button className="restart__button" onClick={onClick}>
        Lukk{' '}
        <img
          className="close-chat-icon"
          src={`${frontendConfig.cdnUrlInternal}/vink/images/icons/cross_bold.svg`}
          alt="Close chat icon"
        />
      </button>
    </div>
  );
};

export default RestartButton;
