import React from 'react';

import NavigationLink from './NavigationLink.js';
import { LinkWithPulse } from '../../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';
import {
  getLoginUrl,
  loginAction,
  logoutAction,
} from '../../../../../../public-src/core/js/schibsted-account/index.server.js';
import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';

const { cdnUrlInternal } = frontendConfig;

const NavigationLoginButton: React.FC<{
  hasSession: boolean;
  activeSection?: string;
}> = ({ hasSession, activeSection }) => {
  if (!hasSession) {
    return (
      <LinkWithPulse
        className="link login"
        href={getLoginUrl()}
        id="navigation:login-button"
        name="Login-button navigation header"
        onResolveAction={() =>
          loginAction({ state: `vink-${window.location.href}` })
        }
        preventDefaultAction={true}
      >
        <div className="icon">
          <img
            src={`${cdnUrlInternal}/vink/images/header/v2/logout-icon.svg`}
            alt={`Logg inn-knapp`}
          />
        </div>
        <div className="section-title">Logg inn</div>
      </LinkWithPulse>
    );
  }

  if (hasSession) {
    return (
      <>
        <NavigationLink
          className="link login"
          location="https://kundeportal.aftenposten.no/minside"
          name="Min side"
          imgUrl={`${cdnUrlInternal}/vink/images/header/v2/profile.svg`}
          activeSection={activeSection}
        />

        <button className="link logout" onClick={logoutAction}>
          <div className="icon">
            <img
              src={`${cdnUrlInternal}/vink/images/header/v2/logout-icon.svg`}
              alt="Logg ut-knapp"
            />
          </div>
          Logg ut
        </button>
      </>
    );
  }

  return null;
};

export default NavigationLoginButton;
