import React from 'react';

import type { FrapiTeaserItemSized } from '../../../types/frapi-types.js';

import Image from './teaserComponents/Image.js';
import TeaserKicker from './teaserComponents/TeaserKicker.js';
import TeaserTag from './teaserComponents/TeaserTag.js';
import { teaserImageMap } from '../commonComponents/imageUtils.js';
import TeaserGrade from './teaserComponents/TeaserGrade.js';

const TeaserStandard: React.FC<FrapiTeaserItemSized> = (teaser) => {
  const { image, title, grade, mobile } = teaser;
  const imgSize =
    teaserImageMap[mobile ? 'mobile' : 'desktop'][teaser.size || 'medium'];

  return (
    <>
      {image && (
        <Image
          imageAsset={image}
          width={imgSize.w}
          height={imgSize.h}
          title={title}
        />
      )}
      <div className="text-wrapper">
        <TeaserKicker {...teaser} />
        <div className="title">{title}</div>
        {!(mobile && teaser.size === 'small') && (
          <div className="grade-tags">
            {grade && <TeaserGrade grade={grade} />}
            <TeaserTag tags={teaser.tags} section={teaser.section} />
          </div>
        )}
      </div>
    </>
  );
};
export default TeaserStandard;
