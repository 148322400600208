import type {
  FrapiTeaserItemSized,
  TeaserSize,
} from '../../../types/frapi-types.js';
import type { ImageAsset } from '../../../../core/data-sources/content-components/types.js';

type TeaserProps = {
  image: ImageAsset;
  size?: TeaserSize;
  teaserWithIllustration?: boolean;
  mobile?: boolean;
};

export const getImageUrl = (id: string, width: number, height: number) => {
  return `https://premium.vgc.no/ap/images/${id}?fit=crop&h=${height}&q=80&tight=true&w=${width}`;
};

export const isSmall = (size: TeaserSize): boolean => {
  return size === 'small';
};

export const getTeaserImageSize = (teaser: TeaserProps) => {
  const width = teaser.image.size?.width;
  const height = teaser.image.size?.height;
  if (!width || !height) return null;

  const r = calculateRatio(width, height);

  if (teaser.teaserWithIllustration) {
    return { w: 240, h: 240 };
  } else if (teaser.size) {
    return teaserSizes(r, teaser.size);
  } else {
    return null;
  }
};

export const calculateRatio = (w: number, h: number) =>
  Math.round((w / h) * 10) / 10;

type ImageDimensions = { w: number; h: number };

const teaserSizes = (r: number, size: TeaserSize): ImageDimensions => {
  const smallDimensionsMap: Record<number, ImageDimensions> = {
    1: { w: 360, h: 360 },
    1.8: { w: 360, h: 202 },
    0.7: { w: 360, h: 540 },
    0.9: { w: 360, h: 326 },
    2: { w: 360, h: 810 },
  };

  const largeDimensionsMap: Record<number, ImageDimensions> = {
    1: { w: 720, h: 720 },
    1.8: { w: 780, h: 439 },
    0.7: { w: 720, h: 1080 },
    0.9: { w: 650, h: 720 },
    2: { w: 720, h: 320 },
  };

  const defaultDimensions = isSmall(size)
    ? { w: 360, h: 240 }
    : { w: 720, h: 480 };

  return isSmall(size)
    ? (smallDimensionsMap[r] ?? defaultDimensions)
    : (largeDimensionsMap[r] ?? defaultDimensions);
};

export const articleSizes = (
  ratio: number | null,
  isMobile: boolean,
  fullWidth: boolean,
): ImageDimensions => {
  const ratioMap: Record<number, ImageDimensions> = {
    0.7: { w: isMobile ? 1080 : 1440, h: isMobile ? 1440 : 2160 },
    0.8: { w: 680, h: 853 },
    0.9: { w: 650, h: 720 },
    1: { w: 960, h: 960 },
    1.8: { w: isMobile ? 780 : 980, h: isMobile ? 439 : 551 },
    2: { w: 960, h: isMobile ? 405 : 426 },
  };

  if (fullWidth) {
    return isMobile ? { w: 980, h: 1536 } : { w: 2048, h: 878 }; // 21:9
  }

  return (
    (ratio && ratioMap[ratio]) ||
    (isMobile ? { w: 960, h: 640 } : { w: 1280, h: 853 }) // Default 3:2
  );
};

export const teaserImageMap = {
  mobile: {
    small: { w: 180, h: 180 },
    medium: { w: 576, h: 384 },
    big: { w: 576, h: 384 },
  },
  desktop: {
    small: { w: 576, h: 384 },
    medium: { w: 680, h: 453 },
    big: { w: 980, h: 653 },
  },
};

export const hasPrice = (teaser: FrapiTeaserItemSized) => {
  return teaser.priceLevel && teaser.priceLevel !== 'none';
};
