import React from 'react';

import type { FrapiTeaserItemSized } from '../../../types/frapi-types.js';

import Image from './teaserComponents/Image.js';
import { teaserImageMap } from '../commonComponents/imageUtils.js';
import TeaserWrapper from './TeaserWrapper.js';

const TeaserStandard: React.FC<FrapiTeaserItemSized> = (teaser) => {
  const { image, title } = teaser;
  const imgSize = teaserImageMap['desktop']['small'];

  return (
    <TeaserWrapper
      classNames={'teaser-widget'}
      teaser={teaser}
      isMobile={false}
    >
      {image && (
        <Image
          imageAsset={image}
          width={imgSize.w}
          height={imgSize.h}
          title={title}
        />
      )}
      <div className="title">{title}</div>
    </TeaserWrapper>
  );
};
export default TeaserStandard;
