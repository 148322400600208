import type {
  FrapiBundleItem,
  FrapiTeaserItem,
  FrontElement,
  TeaserWithChunk,
} from '../../../types/frapi-types.js';

const takeupRow = (
  el: FrapiBundleItem | FrapiTeaserItem | FrontElement,
): boolean =>
  (el.type === 'teaser' && el.fullWidthTeaser) || el.type === 'bundle';

export function splitToChunks(items: (FrapiBundleItem | FrapiTeaserItem)[]) {
  const chunks: FrontElement[] = [];

  for (const el of items) {
    // full width teasers and bundles take up a whole row (array cell in our case)
    if (takeupRow(el)) {
      chunks.push(el);
    } else {
      const last = chunks[chunks.length - 1];

      // create a new chunk if this is the very first teaser or if the last element takes up a row
      if (!last || takeupRow(last)) {
        chunks.push(makeNewChunk(el as FrapiTeaserItem));
      } else {
        const lastChunkContent = (last as TeaserWithChunk).items;

        // if chunk too large make new chunk
        if (lastChunkContent.length >= pickChunkSize(chunks.length)) {
          chunks.push(makeNewChunk(el as FrapiTeaserItem));
        } else {
          (last as TeaserWithChunk).items.push(el as FrapiTeaserItem); // push in already existing chunk
        }
      }
    }
  }

  // fill in single chunks with the first element of the next chunk
  const seenChunks: FrontElement[] = [];
  for (const curr of chunks) {
    const single = seenChunks.find(
      (c) => c.type === 'chunk' && c.items.length === 1,
    ) as TeaserWithChunk;

    if (single && curr.type === 'chunk') {
      single.items.push(curr.items[0]);
      curr.items.shift();
    } else {
      seenChunks.push(curr);
    }
  }

  return chunks;
}

const makeNewChunk = (element: FrapiTeaserItem): FrontElement => {
  return { ...element, type: 'chunk', items: [element] };
};

const chunkSizes = [2, 3, 5, 2, 4, 3, 2, 3, 5, 4, 3, 4, 2, 3, 4];

const pickChunkSize = (currentRow: number): number => {
  return chunkSizes[currentRow];
};
