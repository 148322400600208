import React from 'react';

import type {
  ByfunnFrapiBlendResponse,
  FrapiBundleItem,
  FrapiTeaserItem,
} from '../../../types/frapi-types.js';

import Carousel from '../../components/commonComponents/Carousel.js';
import TeaserWidget from '../../components/teasers/TeaserWidget.js';

export const filterGuideWidgetFromSubfeed = (
  items: (FrapiTeaserItem | FrapiBundleItem)[],
  feed: ByfunnFrapiBlendResponse,
) =>
  items.filter(
    (d) => d.type === 'teaser' && !feed.items.some((f) => f.id === d.id),
  );

const GuidesWidget: React.FC<{
  data: ByfunnFrapiBlendResponse;
  feed: ByfunnFrapiBlendResponse;
  index: number;
  isMobile: boolean;
}> = ({ data, index, isMobile, feed }) => (
  <section className="guides-wrapper">
    <div className="guides-title">POPULÆRE GUIDER</div>

    <Carousel>
      {filterGuideWidgetFromSubfeed(data.items, feed).map((t) => (
        <TeaserWidget
          mobile={isMobile}
          key={`guides-${t.id}`}
          index={index}
          {...(t.type === 'teaser' ? t : t.items[0])}
        />
      ))}
    </Carousel>
  </section>
);

export default GuidesWidget;
