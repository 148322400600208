import React from 'react';

export interface AdProps {
  id: string;
  cssClass: string;
  isMobile: boolean;
  containerClass?: string;
}

const Ad = ({ id, cssClass, isMobile, containerClass = '' }: AdProps) => {
  const mobileStyle = isMobile ? 'ad-mobile' : '';
  const containerClasses =
    `ad-container ${mobileStyle} ${containerClass}`.trim();
  const adClasses = `ad ${cssClass}`.trim();

  return (
    <div className={containerClasses}>
      <div id={id} className={adClasses} />
    </div>
  );
};

export default Ad;
