import React, { useState } from 'react';

import { frontendConfig } from '../../../../../../core/system/config/frontend.server.js';
import { usePulseContext } from '../../../../../../core/mandatory-integrations/pulse/PulseProvider.js';

const RateChat = () => {
  const [ratingGiven, setRatingGiven] = useState(false);
  const { trackClickUIElement } = usePulseContext();

  const ratingOptions = [
    { value: 'Ja', icon: 'smiley.svg', alt: 'Smiley icon' },
    {
      value: 'Vet ikke',
      icon: 'smiley_blank.svg',
      alt: 'Blank smiley icon',
    },
    {
      value: 'Nei',
      icon: 'smiley_angry.svg',
      alt: 'Angry smiley icon',
    },
  ];

  const handleButtonClick = (rating: string) => {
    trackClickUIElement({
      object: {
        id: `vink-o-mat-rating`,
        name: `Rated vink-o-mat: ${rating}`,
        elementType: `Button`,
      },
    });
    setRatingGiven(true);
  };

  return (
    <>
      <p className="rate-question">Var dette nyttig?</p>
      <div className="rate-button-container">
        {ratingGiven ? (
          <>
            <img
              className="heart-icon-img"
              src={`${frontendConfig.cdnUrlInternal}/vink/images/icons/heart.svg`}
              alt="Heart icon"
            />
            <p className="feedback-paragraph">
              Tusen takk for din tilbakemelding!
            </p>
          </>
        ) : (
          ratingOptions.map(({ value, icon, alt }) => (
            <button
              key={value}
              className="rate-button"
              onClick={() => handleButtonClick(value)}
            >
              <img
                className="rate-icon-img"
                src={`${frontendConfig.cdnUrlInternal}/vink/images/icons/${icon}`}
                alt={alt}
              />
              {value}
            </button>
          ))
        )}
      </div>
    </>
  );
};

export default RateChat;
