import type { Access } from '../data-sources/frapi/data-types.js';

const subscriberOnly = (access: Access): boolean =>
  access && access.restrictions && access.restrictions.includes('closed');

const metered = (access: Access): boolean =>
  access && access.restrictions && access.restrictions.includes('automatic');

export const accessLevel = (access: Access): 'Paid' | 'Meter' | 'Free' =>
  metered(access) ? 'Meter' : subscriberOnly(access) ? 'Paid' : 'Free';

export const accessLevelName = (access: Access): string => accessLevel(access);
