import { isLocalhost } from './utils/is-localhost.js';

export const loadServiceWorker = (): void => {
  if (!navigator.serviceWorker) {
    return;
  }

  const DISABLE_SERVICE_WORKER_ON_LOCALHOST = true;

  if (isLocalhost() && DISABLE_SERVICE_WORKER_ON_LOCALHOST) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.map((registration) => {
        registration.unregister();
      });
    });

    return;
  }

  navigator.serviceWorker.register('/service-worker.js').then(
    (registration) => {
      console.log(
        'ServiceWorker registration successful with scope: ',
        registration.scope,
      );
    },
    (err) => {
      console.log('ServiceWorker registration failed: ', err);
    },
  );
};

export const registerServiceWorker = (): void => {
  window.addEventListener('load', () => loadServiceWorker());
};
